import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import {
  EBnibTransactionSortField,
  IBnibTransaction,
  PBnibTransactionFilter,
} from "../entities";
import IBnibTransactionRepository from "../repository";

export default class BnibTransactionRepository
  implements IBnibTransactionRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private BNIB_TRANSACTION_ROUTE = "/admin/bnib-transaction";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  buildFilter = (filter: PBnibTransactionFilter) => {
    return filter;
  };

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getBnibTransaction(
    pagination: IPagination,
    filter: PBnibTransactionFilter,
    sorts: ISort<EBnibTransactionSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IBnibTransaction[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      (val) => val
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.BNIB_TRANSACTION_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getBnibTransactionByCode(code: string): Promise<IBnibTransaction> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.BNIB_TRANSACTION_ROUTE}/${code}`)
    );
    if (err) {
      throw this.getError(err);
    }
    const bnibTransaction: IBnibTransaction = response.data;
    bnibTransaction.accessLog = JSON.parse(bnibTransaction.access_log);
    bnibTransaction.product_detail.detail_image_urls = bnibTransaction
      .product_detail.detail_image_url
      ? bnibTransaction.product_detail.detail_image_url.split(/,/)
      : [];
    bnibTransaction.defected_image_urls = bnibTransaction.defected_image_url
      ? bnibTransaction.defected_image_url.split(/,/)
      : [];
    return bnibTransaction;
  }

  async arriveBnibTransaction(code: string): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.BNIB_TRANSACTION_ROUTE}/${code}/arrived`
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async acceptBnibTransaction(code: string): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.BNIB_TRANSACTION_ROUTE}/${code}/accepted`
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async rejectBnibTransaction(code: string, reason: string): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.BNIB_TRANSACTION_ROUTE}/${code}/rejected`,
        {
          reject_reason: reason,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async refundBnibTransaction(
    code: string,
    courier_slug: string,
    tracking_code: string
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.BNIB_TRANSACTION_ROUTE}/${code}/refund`,
        {
          courier_slug,
          tracking_code,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async defectBnibTransaction(code: string, formData: FormData): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.BNIB_TRANSACTION_ROUTE}/${code}/defect`,
        formData
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async legitCheckBnibTransaction(code: string, result: string): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.BNIB_TRANSACTION_ROUTE}/${code}/lc`,
        { result }
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async sendBnibTransaction(
    code: string,
    courier_slug: string,
    tracking_code: string,
    shipping_cost: number
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.BNIB_TRANSACTION_ROUTE}/${code}/send`,
        {
          courier_slug,
          tracking_code,
          shipping_cost,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async disputeBnibTransaction(
    code: string,
    reject_reason: string
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.BNIB_TRANSACTION_ROUTE}/${code}/disputed`,
        {
          reject_reason,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
