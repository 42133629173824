import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { reducer as formReducer } from "redux-form";
import { combineEpics, createEpicMiddleware } from "redux-observable";

import adminUserReducer from "./adminUser/ducks";
import adminUserEpics from "./adminUser/epics";
import authReducer from "./auth/ducks";
import bannerReducer from "./banner/ducks";
import bannerEpics from "./banner/epics";
import bnibBuyOrderReducer from "./bnibBuyOrder/ducks";
import bnibBuyOrderEpics from "./bnibBuyOrder/epics";
import bnibProductReducer from "./bnibProduct/ducks";
import bnibProductEpics from "./bnibProduct/epics";
import bnibTransactionReducer from "./bnibTransaction/ducks";
import bnibTransactionEpics from "./bnibTransaction/epics";
import configReducer from "./config/ducks";
import configEpics from "./config/epics";
import depositFeeReducer from "./depositFee/ducks";
import depositFeeEpics from "./depositFee/epics";
import featuredProductReducer from "./featuredProduct/ducks";
import featuredProductEpics from "./featuredProduct/epics";
import floatingFundReducer from "./floatingFund/ducks";
import floatingFundEpics from "./floatingFund/epics";
import identificationReducer from "./identification/ducks";
import identificationEpics from "./identification/epics";
import productReducer from "./product/ducks";
import productEpics from "./product/epics";
import productBrandReducer from "./productBrand/ducks";
import productBrandEpics from "./productBrand/epics";
import productCategoryReducer from "./productCategory/ducks";
import productCategoryEpics from "./productCategory/epics";
import productCategoryGroupReducer from "./productCategoryGroup/ducks";
import productCategoryGroupEpics from "./productCategoryGroup/epics";
import productRequestReducer from "./productRequest/ducks";
import productRequestEpics from "./productRequest/epics";
import promoCodeReducer from "./promoCode/ducks";
import promoCodeEpics from "./promoCode/epics";
import referralRewardReducer from "./referralReward/ducks";
import referralRewardEpics from "./referralReward/epics";
import revenueReducer from "./revenue/ducks";
import revenueEpics from "./revenue/epics";
import specialCategoryReducer from "./specialCategory/ducks";
import specialCategoryEpics from "./specialCategory/epics";
import specialCategoryListReducer from "./specialCategoryList/ducks";
import specialCategoryListEpics from "./specialCategoryList/epics";
import statisticReducer from "./statistic/ducks";
import statisticEpics from "./statistic/epics";
import topUpReducer from "./topUp/ducks";
import topUpEpics from "./topUp/epics";
import userReducer from "./user/ducks";
import userEpics from "./user/epics";
import walletMutationReducer from "./walletMutation/ducks";
import walletMutationEpics from "./walletMutation/epics";
import withdrawRequestReducer from "./withdrawRequest/ducks";
import withdrawRequestEpics from "./withdrawRequest/epics";

export const rootReducer = combineReducers({
  adminUser: adminUserReducer,
  auth: authReducer,
  banner: bannerReducer,
  bnibBuyOrder: bnibBuyOrderReducer,
  bnibProduct: bnibProductReducer,
  bnibTransaction: bnibTransactionReducer,
  config: configReducer,
  depositFee: depositFeeReducer,
  featuredProduct: featuredProductReducer,
  form: formReducer,
  floatingFund: floatingFundReducer,
  identification: identificationReducer,
  product: productReducer,
  productBrand: productBrandReducer,
  productCategory: productCategoryReducer,
  productCategoryGroup: productCategoryGroupReducer,
  productRequest: productRequestReducer,
  promoCode: promoCodeReducer,
  referralReward: referralRewardReducer,
  revenue: revenueReducer,
  specialCategory: specialCategoryReducer,
  specialCategoryList: specialCategoryListReducer,
  statistic: statisticReducer,
  topUp: topUpReducer,
  user: userReducer,
  walletMutation: walletMutationReducer,
  withdrawRequest: withdrawRequestReducer,
});

export const rootEpic = combineEpics(
  ...adminUserEpics,
  ...bannerEpics,
  ...bnibBuyOrderEpics,
  ...bnibProductEpics,
  ...bnibTransactionEpics,
  ...configEpics,
  ...depositFeeEpics,
  ...featuredProductEpics,
  ...floatingFundEpics,
  ...identificationEpics,
  ...productEpics,
  ...productBrandEpics,
  ...productCategoryEpics,
  ...productCategoryGroupEpics,
  ...productRequestEpics,
  ...promoCodeEpics,
  ...referralRewardEpics,
  ...revenueEpics,
  ...specialCategoryEpics,
  ...specialCategoryListEpics,
  ...statisticEpics,
  ...topUpEpics,
  ...userEpics,
  ...walletMutationEpics,
  ...withdrawRequestEpics
);

export function configureStore() {
  const epicMiddleware = createEpicMiddleware();
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(epicMiddleware))
  );
  epicMiddleware.run(rootEpic);
  return store;
}

export type RootState = ReturnType<typeof rootReducer>;
