import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";
import shallowDiff from "shallow-diff";

import {
  EDepositFeeSortField,
  IDepositFee,
  PDepositFee,
  PDepositFeeFilter,
} from "../entities";
import IDepositFeeRepository from "../repository";
import IDepositFeeUsecase from "../usecase";

export default class DepositFeeUsecase
  implements IDepositFeeUsecase<CancelTokenSource> {
  private depositFeeRepo: IDepositFeeRepository<CancelTokenSource>;

  constructor(depositFeeRepo: IDepositFeeRepository<CancelTokenSource>) {
    this.depositFeeRepo = depositFeeRepo;
  }

  async getDepositFee(
    pagination: IPagination,
    filter: PDepositFeeFilter,
    sorts: ISort<EDepositFeeSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IDepositFee[], IMeta]> {
    return await this.depositFeeRepo.getDepositFee(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getDepositFeeById(id: number): Promise<IDepositFee> {
    return await this.depositFeeRepo.getDepositFeeById(id);
  }

  async createDepositFee(depositFee: PDepositFee): Promise<void> {
    await this.depositFeeRepo.createDepositFee(depositFee);
  }

  async updateDepositFee(
    oldDepositFee: PDepositFee,
    newDepositFee: PDepositFee
  ): Promise<void> {
    const diffDepositFee = _.pick(
      newDepositFee,
      shallowDiff(oldDepositFee, newDepositFee).updated
    ) as PDepositFee;

    await this.depositFeeRepo.updateDepositFee(
      oldDepositFee.id,
      diffDepositFee
    );
  }

  async deleteDepositFee(id: number): Promise<void> {
    await this.depositFeeRepo.deleteDepositFee(id);
  }
}
