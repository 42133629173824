import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import {
  EWithdrawRequestSortField,
  IWithdrawRequest,
  PWithdrawRequestFilter,
} from "../entities";
import IWithdrawRequestRepository from "../repository";

export default class WithdrawRequestRepository
  implements IWithdrawRequestRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private WITHDRAW_REQUEST_ROUTE = "/admin/withdraw-request";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private buildFilter(filter: PWithdrawRequestFilter) {
    return filter;
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getWithdrawRequest(
    pagination: IPagination,
    filter: PWithdrawRequestFilter,
    sorts: ISort<EWithdrawRequestSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IWithdrawRequest[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      (val) => val
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.WITHDRAW_REQUEST_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getWithdrawRequestById(id: number): Promise<IWithdrawRequest> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.WITHDRAW_REQUEST_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async approveWithdrawRequest(
    id: number,
    transaction_code: string
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.WITHDRAW_REQUEST_ROUTE}/${id}/approve`,
        {
          transaction_code,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async rejectWithdrawRequest(
    id: number,
    rejected_reason: string
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.WITHDRAW_REQUEST_ROUTE}/${id}/reject`,
        {
          rejected_reason,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
