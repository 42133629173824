import _ from "lodash";
import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import LazyLoaderIndicator from "src/components/misc/LazyLoaderIndicator";

import { loginRoutes } from "./loginRoutes/Routes";

export default () => {
  return (
    <Suspense fallback={<LazyLoaderIndicator />}>
      <Switch>
        {_.values(loginRoutes)
          .filter((route) => Boolean(route.Page))
          .map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                component={route.Page}
              />
            );
          })}

        <Route path="*">
          <Redirect to={loginRoutes.LOGIN.path} />
        </Route>
      </Switch>
    </Suspense>
  );
};
