import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import { EUserSortField, IUser, PUserFilter } from "../entities";
import IUserRepository from "../repository";
import IUserUsecase from "../usecase";

export default class UserUsecase implements IUserUsecase<CancelTokenSource> {
  private userRepo: IUserRepository<CancelTokenSource>;

  constructor(userRepo: IUserRepository<CancelTokenSource>) {
    this.userRepo = userRepo;
  }

  async getUser(
    pagination: IPagination,
    filter: PUserFilter,
    sorts: ISort<EUserSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IUser[], IMeta]> {
    return await this.userRepo.getUser(pagination, filter, sorts, signal);
  }

  async getUserById(id: number): Promise<IUser> {
    return await this.userRepo.getUserById(id);
  }
}
