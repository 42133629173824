import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import {
  EProductBrandSortField,
  IProductBrand,
  PProductBrandFilter,
} from "../entities";
import IProductBrandRepository from "../repository";

export default class ProductBrandRepository
  implements IProductBrandRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private PRODUCT_BRAND_ROUTE = "/admin/product-brand";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  buildFilter = (filter: PProductBrandFilter) => {
    return filter;
  };

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getProductBrand(
    pagination: IPagination,
    filter: PProductBrandFilter,
    sorts: ISort<EProductBrandSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IProductBrand[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      Boolean
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.PRODUCT_BRAND_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getProductBrandById(id: number): Promise<IProductBrand> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.PRODUCT_BRAND_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }

    return response.data;
  }

  async createProductBrand(formData: FormData): Promise<void> {
    const [err, response] = await goPromise(
      this.apiClientFactory().post(`${this.PRODUCT_BRAND_ROUTE}`, formData)
    );
    if (err) {
      throw this.getError(err);
    }

    return response.data;
  }

  async updateProductBrand(id: number, formData: FormData): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(
        `${this.PRODUCT_BRAND_ROUTE}/${id}`,
        formData
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
