import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import { EProductSortField, IProduct, PProductFilter } from "../entities";
import IProductRepository from "../repository";

export default class ProductRepository
  implements IProductRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private PRODUCT_ROUTE = "/admin/product";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  buildFilter = (filter: PProductFilter) => {
    const release_date = filter.release_date_enabled
      ? `${filter.release_date_start},${filter.release_date_end}`
      : undefined;

    const clonedFilter = { ...filter };
    delete clonedFilter.release_date_enabled;
    delete clonedFilter.release_date_start;
    delete clonedFilter.release_date_end;

    return {
      ...clonedFilter,
      release_date,
    };
  };

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getProduct(
    pagination: IPagination,
    filter: PProductFilter,
    sorts: ISort<EProductSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IProduct[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      Boolean
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.PRODUCT_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getProductById(id: number): Promise<IProduct> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.PRODUCT_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }
    const product: IProduct = response.data;
    product.detail_image_urls = Boolean(product.detail_image_url)
      ? product.detail_image_url.split(",")
      : [];
    return product;
  }

  async createProduct(formData: FormData): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(`${this.PRODUCT_ROUTE}`, formData)
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async updateProduct(id: number, formData: FormData): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(`${this.PRODUCT_ROUTE}/${id}`, formData)
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async deleteProduct(id: number): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().delete(`${this.PRODUCT_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
