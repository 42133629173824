import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import { EUserSortField, IUser, PUserFilter } from "../entities";
import IUserRepository from "../repository";

export default class UserRepository
  implements IUserRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private USER_ROUTE = "/admin/user";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  private buildFilter(filter: PUserFilter) {
    const created_at = filter.created_at_enabled
      ? `${filter.created_at_start},${filter.created_at_end}`
      : undefined;

    const clonedFilter = { ...filter };
    delete clonedFilter.created_at_enabled;
    delete clonedFilter.created_at_start;
    delete clonedFilter.created_at_end;

    return {
      ...clonedFilter,
      created_at,
    };
  }

  async getUser(
    pagination: IPagination,
    filter: PUserFilter,
    sorts: ISort<EUserSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IUser[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      (val) => val
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.USER_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getUserById(id: number): Promise<IUser> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.USER_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }
}
