import { IRoute } from "@delanobgt/admin-core/util/types";
import {
  AttachMoney as AttachMoneyIcon,
  Bookmark as BookmarkIcon,
  Dashboard as DashboardIcon,
  ExitToApp as ExitToAppIcon,
  Image as ImageIcon,
  LibraryBooks as LibraryBooksIcon,
  Money as MoneyIcon,
  MoneyOff as MoneyOffIcon,
  People as PeopleIcon,
  Poll as PollIcon,
  Receipt as ReceiptIcon,
  SettingsApplications as SettingsApplicationsIcon,
  Style as StyleIcon,
  VerticalAlignBottom as VerticalAlignBottomIcon,
  VerticalAlignTop as VerticalAlignTopIcon,
  VpnKey as VpnKeyIcon,
} from "@material-ui/icons";
import { lazy } from "react";

const AdminUser = lazy(() => import("src/pages/AdminUser"));
const Logout = lazy(() => import("src/pages/Auth/Logout/Logout"));
const Banner = lazy(() => import("src/pages/Banner/Banner"));
const BnibBuyOrder = lazy(() => import("src/pages/BnibBuyOrder/BnibBuyOrder"));
const BnibProduct = lazy(() => import("src/pages/BnibProduct/BnibProduct"));
const BnibTransaction = lazy(() =>
  import("src/pages/BnibTransaction/BnibTransaction")
);
const Config = lazy(() => import("src/pages/Config/Config"));
const Dashboard = lazy(() => import("src/pages/Dashboard/Dashboard"));
const DepositFee = lazy(() => import("src/pages/DepositFee/DepositFee"));
const FeaturedProduct = lazy(() =>
  import("src/pages/FeaturedProduct/FeaturedProduct")
);
const FloatingFund = lazy(() => import("src/pages/FloatingFund/FloatingFund"));
const Identification = lazy(() =>
  import("src/pages/Identification/Identification")
);
const Newsletter = lazy(() => import("src/pages/Newsletter/Newsletter"));
const Product = lazy(() => import("src/pages/Product/Product"));
const ProductBrand = lazy(() => import("src/pages/ProductBrand/ProductBrand"));
const ProductCategory = lazy(() =>
  import("src/pages/ProductCategory/ProductCategory")
);
const ProductCategoryGroup = lazy(() =>
  import("src/pages/ProductCategoryGroup/ProductCategoryGroup")
);
const ProductRequest = lazy(() =>
  import("src/pages/ProductRequest/ProductRequest")
);
const PromoCode = lazy(() => import("src/pages/PromoCode/PromoCode"));
const Revenue = lazy(() => import("src/pages/Revenue/Revenue"));
const SpecialCategory = lazy(() =>
  import("src/pages/SpecialCategory/SpecialCategory")
);
const TopUp = lazy(() => import("src/pages/TopUp/TopUp"));
const User = lazy(() => import("src/pages/User/User"));
const WithdrawRequest = lazy(() =>
  import("src/pages/WithdrawRequest/WithdrawRequest")
);

export type TAdminRouteNames =
  | "ADMIN_USER"
  | "BANNER"
  | "BNIB_BUY_ORDER"
  | "BNIB_PRODUCT"
  | "BNIB_TRANSACTION"
  | "CONFIG"
  | "DASHBOARD"
  | "DEPOSIT_FEE"
  | "FEATURED_PRODUCT"
  | "FLOATING_FUND"
  | "IDENTIFICATION"
  | "LOGOUT"
  | "NEWSLETTER"
  | "PRODUCT"
  | "PRODUCT_BRAND"
  | "PRODUCT_CATEGORY"
  | "PRODUCT_CATEGORY_GROUP"
  | "PRODUCT_REQUEST"
  | "PROMO_CODE"
  | "REVENUE"
  | "SPECIAL_CATEGORY"
  | "TOP_UP"
  | "USER"
  | "WITHDRAW_REQUEST";

export const adminRoutes: Record<TAdminRouteNames, IRoute> = {
  ADMIN_USER: {
    Icon: VpnKeyIcon,
    path: "/admin-user",
    Page: AdminUser,
  },
  BANNER: {
    Icon: ImageIcon,
    path: "/banner",
    Page: Banner,
  },
  BNIB_BUY_ORDER: {
    Icon: StyleIcon,
    path: "/bnib-buy-order",
    Page: BnibBuyOrder,
  },
  BNIB_PRODUCT: {
    Icon: StyleIcon,
    path: "/bnib-product",
    Page: BnibProduct,
  },
  BNIB_TRANSACTION: {
    Icon: ReceiptIcon,
    path: "/bnib-transaction",
    Page: BnibTransaction,
  },
  CONFIG: {
    Icon: SettingsApplicationsIcon,
    path: "/config",
    Page: Config,
  },
  DASHBOARD: {
    Icon: DashboardIcon,
    path: "/dashboard",
    Page: Dashboard,
  },
  DEPOSIT_FEE: {
    Icon: AttachMoneyIcon,
    path: "/deposit-fee",
    Page: DepositFee,
  },
  FEATURED_PRODUCT: {
    Icon: StyleIcon,
    path: "/featured-product",
    Page: FeaturedProduct,
  },
  FLOATING_FUND: {
    Icon: MoneyIcon,
    path: "/floating-fund",
    Page: FloatingFund,
  },
  IDENTIFICATION: {
    Icon: PeopleIcon,
    path: "/identification",
    Page: Identification,
  },
  LOGOUT: {
    Icon: ExitToAppIcon,
    path: "/logout",
    Page: Logout,
  },
  NEWSLETTER: {
    Icon: LibraryBooksIcon,
    path: "/newsletter",
    Page: Newsletter,
  },
  PRODUCT: {
    Icon: StyleIcon,
    path: "/product",
    Page: Product,
  },
  PRODUCT_BRAND: {
    Icon: StyleIcon,
    path: "/product-brand",
    Page: ProductBrand,
  },
  PRODUCT_CATEGORY: {
    Icon: StyleIcon,
    path: "/product-category",
    Page: ProductCategory,
  },
  PRODUCT_CATEGORY_GROUP: {
    Icon: StyleIcon,
    path: "/product-category-group",
    Page: ProductCategoryGroup,
  },
  PRODUCT_REQUEST: {
    Icon: PollIcon,
    path: "/product-request",
    Page: ProductRequest,
  },
  PROMO_CODE: {
    Icon: MoneyOffIcon,
    path: "/promo-code",
    Page: PromoCode,
  },
  REVENUE: {
    Icon: MoneyIcon,
    path: "/revenue",
    Page: Revenue,
  },
  SPECIAL_CATEGORY: {
    Icon: BookmarkIcon,
    path: "/special-category",
    Page: SpecialCategory,
  },
  TOP_UP: {
    Icon: VerticalAlignBottomIcon,
    path: "/top-up",
    Page: TopUp,
  },
  USER: {
    Icon: PeopleIcon,
    path: "/user",
    Page: User,
  },
  WITHDRAW_REQUEST: {
    Icon: VerticalAlignTopIcon,
    path: "/withdraw-request",
    Page: WithdrawRequest,
  },
};
