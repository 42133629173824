import { IShippingAddress } from "../entities";
import IShippingAddressRepository from "../repository";
import IShippingAddressUsecase from "../usecase";

export default class ShippingAddressUsecase implements IShippingAddressUsecase {
  private shippingAddressRepo: IShippingAddressRepository;

  constructor(shippingAddressRepo: IShippingAddressRepository) {
    this.shippingAddressRepo = shippingAddressRepo;
  }

  async getShippingAddressById(id: number): Promise<IShippingAddress> {
    return await this.shippingAddressRepo.getShippingAddressById(id);
  }

  async getShippingAddressByUserId(
    userId: number
  ): Promise<IShippingAddress[]> {
    return await this.shippingAddressRepo.getShippingAddressByUserId(userId);
  }
}
