import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";
import shallowDiff from "shallow-diff";

import {
  EProductCategoryGroupSortField,
  IProductCategoryGroup,
  PProductCategoryGroupFilter,
} from "../entities";
import IProductCategoryGroupRepository from "../repository";
import IProductCategoryGroupUsecase from "../usecase";

export default class ProductCategoryGroupUsecase
  implements IProductCategoryGroupUsecase<CancelTokenSource> {
  private productCategoryGroupRepo: IProductCategoryGroupRepository<
    CancelTokenSource
  >;

  constructor(
    productCategoryGroupRepo: IProductCategoryGroupRepository<CancelTokenSource>
  ) {
    this.productCategoryGroupRepo = productCategoryGroupRepo;
  }

  async getProductCategoryGroup(
    pagination: IPagination,
    filter: PProductCategoryGroupFilter,
    sorts: ISort<EProductCategoryGroupSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IProductCategoryGroup[], IMeta]> {
    return await this.productCategoryGroupRepo.getProductCategoryGroup(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getProductCategoryGroupById(
    id: number
  ): Promise<IProductCategoryGroup> {
    return await this.productCategoryGroupRepo.getProductCategoryGroupById(id);
  }

  async createProductCategoryGroup(
    productCategoryGroup: IProductCategoryGroup,
    image: any
  ): Promise<void> {
    await this.productCategoryGroupRepo.createProductCategoryGroup(
      productCategoryGroup,
      image
    );
  }

  async updateProductCategoryGroup(
    oldProductCategoryGroup: IProductCategoryGroup,
    newProductCategoryGroup: IProductCategoryGroup,
    image: any
  ): Promise<void> {
    const diffProductCategoryGroup = _.pick(
      newProductCategoryGroup,
      shallowDiff(oldProductCategoryGroup, newProductCategoryGroup).updated
    ) as IProductCategoryGroup;

    await this.productCategoryGroupRepo.updateProductCategoryGroup(
      newProductCategoryGroup.id,
      diffProductCategoryGroup,
      image
    );
  }
}
