import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import { EConfigSortField, IConfig, PConfig, PConfigFilter } from "../entities";
import IConfigRepository from "../repository";

export default class ConfigRepository
  implements IConfigRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private CONFIG_ROUTE = "/admin/config";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  private buildFilter(filter: PConfigFilter) {
    return filter;
  }

  async getConfig(
    pagination: IPagination,
    filter: PConfigFilter,
    sorts: ISort<EConfigSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IConfig[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      Boolean
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.CONFIG_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getConfigByName(name: string): Promise<IConfig> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.CONFIG_ROUTE}/${name}`)
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async updateConfig(id: number, config: PConfig): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(`${this.CONFIG_ROUTE}/${id}`, config)
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
