import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import {
  EReferralRewardSortField,
  IReferralReward,
  PReferralRewardFilter,
} from "../entities";
import IReferralRewardRepository from "../repository";

export default class ReferralRewardRepository
  implements IReferralRewardRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private WALLET_MUTATION_ROUTE = "/admin/referral-reward";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private buildFilter(filter: PReferralRewardFilter) {
    return filter;
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getReferralRewardByUserId(
    userId: number,
    pagination: IPagination,
    filter: PReferralRewardFilter,
    sorts: ISort<EReferralRewardSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IReferralReward[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
        to_user_id: userId,
      },
      (val) => val
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.WALLET_MUTATION_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }
}
