import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EBnibBuyOrderSortField,
  IBnibBuyOrder,
  PBnibBuyOrderFilter,
} from "../entities";
import IBnibBuyOrderRepository from "../repository";
import IBnibBuyOrderUsecase from "../usecase";

export default class BnibBuyOrderUsecase
  implements IBnibBuyOrderUsecase<CancelTokenSource> {
  private bnibBuyOrderRepo: IBnibBuyOrderRepository<CancelTokenSource>;

  constructor(bnibBuyOrderRepo: IBnibBuyOrderRepository<CancelTokenSource>) {
    this.bnibBuyOrderRepo = bnibBuyOrderRepo;
  }

  async getBnibBuyOrder(
    pagination: IPagination,
    filter: PBnibBuyOrderFilter,
    sorts: ISort<EBnibBuyOrderSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IBnibBuyOrder[], IMeta]> {
    return await this.bnibBuyOrderRepo.getBnibBuyOrder(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getBnibBuyOrderByCode(code: string): Promise<IBnibBuyOrder> {
    return await this.bnibBuyOrderRepo.getBnibBuyOrderByCode(code);
  }
}
