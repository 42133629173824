import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";

import {
  EFloatingFundSortField,
  IFloatingFund,
  PFloatingFundFilter,
} from "../entities";
import IFloatingFundRepository from "../repository";

export default class FloatingFundRepository
  implements IFloatingFundRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private FLOATING_FUND_ROUTE = "/admin/statistic/floating-fund";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  buildFilter = (filter: PFloatingFundFilter) => {
    return filter;
  };

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getFloatingFund(
    pagination: IPagination,
    filter: PFloatingFundFilter,
    sorts: ISort<EFloatingFundSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IFloatingFund[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      Boolean
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.FLOATING_FUND_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getFloatingFundExcelData(): Promise<any> {
    const [err, response] = await goPromise(
      this.apiClientFactory({ responseType: "blob" }).get(
        `${this.FLOATING_FUND_ROUTE}/download`
      )
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }
}
