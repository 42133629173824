import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import { EBannerSortField, IBanner, PBanner, PBannerFilter } from "../entities";
import IBannerRepository from "../repository";
import IBannerUsecase from "../usecase";
import moment from "moment";

export default class BannerUsecase
  implements IBannerUsecase<CancelTokenSource> {
  private bannerRepo: IBannerRepository<CancelTokenSource>;

  constructor(bannerRepo: IBannerRepository<CancelTokenSource>) {
    this.bannerRepo = bannerRepo;
  }

  async getBanner(
    pagination: IPagination,
    filter: PBannerFilter,
    sorts: ISort<EBannerSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IBanner[], IMeta]> {
    return await this.bannerRepo.getBanner(pagination, filter, sorts, signal);
  }

  async getBannerById(id: number): Promise<IBanner> {
    return await this.bannerRepo.getBannerById(id);
  }

  async createBanner(banner: PBanner, image: any): Promise<void> {
    const formData = new FormData();
    formData.append("title", banner.title);
    formData.append("type", String(banner.type));
    formData.append("action", String(banner.action));
    formData.append("action_path", banner.action_path);
    formData.append("is_active", String(Boolean(banner.is_active)));
    formData.append("expired_at", String(banner.expired_at));
    formData.append("image", image);

    await this.bannerRepo.createBanner(formData);
  }

  async updateBanner(
    oldBanner: PBanner,
    newBanner: PBanner,
    image: any
  ): Promise<void> {
    const formData = new FormData();
    formData.append("title", newBanner.title);
    formData.append("type", String(newBanner.type));
    formData.append("action", String(newBanner.action));
    formData.append("action_path", newBanner.action_path);
    formData.append("is_active", String(Boolean(newBanner.is_active)));
    formData.append(
      "expired_at",
      moment(newBanner.expired_at).format("YYYY-MM-DD")
    );
    if (image) formData.append("image", image);

    await this.bannerRepo.updateBanner(oldBanner.id, formData);
  }

  async deleteBanner(id: number): Promise<void> {
    await this.bannerRepo.deleteBanner(id);
  }
}
