import Link from "@delanobgt/admin-core/components/link/BasicLink";
import {
  AppBar,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  AccountCircle as AccountCircleIcon,
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
} from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";
import { ICounter } from "src/core/statistic/entities";
import { RootState } from "src/redux";

import MyDrawer from "./nav/MyDrawer";
import NotificationPopover from "./nav/NotificationPopover";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  appBar: {
    zIndex: 5,
  },
}));

export default function Nav() {
  const classes = useStyles({});
  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
  const counter = useSelector<RootState, ICounter>(
    (state) => state.statistic.counter
  );

  const isProfileMenuOpen = Boolean(profileAnchorEl);
  const isNotificationMenuOpen = Boolean(notificationAnchorEl);

  const handleProfileMenuOpen = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setProfileAnchorEl(event.currentTarget);
    },
    [setProfileAnchorEl]
  );

  const handleNotificationMenuOpen = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setNotificationAnchorEl(event.currentTarget);
    },
    [setNotificationAnchorEl]
  );

  const handleProfileMenuClose = React.useCallback(() => {
    setProfileAnchorEl(null);
  }, [setProfileAnchorEl]);

  const handleNotificationMenuClose = React.useCallback(() => {
    setNotificationAnchorEl(null);
  }, [setNotificationAnchorEl]);

  const badgeContent = React.useMemo(() => {
    if (!counter) return null;
    return (
      Number(Boolean(counter.identification)) +
      Number(Boolean(counter.withdraw_request)) +
      Number(Boolean(counter.on_shipping_to_depatu))
    );
  }, [counter]);

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={() => setDrawerOpen(!drawerOpen)}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <img
            alt=""
            src={require("src/assets/images/icons/depatu.png")}
            style={{ height: 25, marginRight: "0.5rem" }}
          />
          <Typography
            className={classes.title}
            variant="h6"
            color="inherit"
            noWrap
          >
            Marketplace
          </Typography>
          <div className={classes.grow} />
          <div>
            <IconButton color="inherit" onClick={handleNotificationMenuOpen}>
              <Badge badgeContent={badgeContent} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              color="inherit"
              edge="end"
              onClick={handleProfileMenuOpen}
            >
              <AccountCircleIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Menu
        anchorEl={profileAnchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isProfileMenuOpen}
        onClose={handleProfileMenuClose}
      >
        <MenuItem onClick={handleProfileMenuClose}>
          Switch to&nbsp;
          <a
            href={process.env.REACT_APP_AMIDIN_URL}
            style={{ textDecoration: "none", color: "#3CB371" }}
          >
            Official Store
          </a>
        </MenuItem>
        <MenuItem onClick={handleProfileMenuClose}>
          <Link to="/logout">Logout</Link>
        </MenuItem>
      </Menu>

      <NotificationPopover
        anchorEl={notificationAnchorEl}
        open={isNotificationMenuOpen}
        onClose={handleNotificationMenuClose}
      />

      <MyDrawer
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        drawerWidth={drawerWidth}
      />
    </div>
  );
}
