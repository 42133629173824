import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EProductRequestSortField,
  IProductRequest,
  PProductRequestFilter,
} from "../entities";
import IProductRequestRepository from "../repository";
import IProductRequestUsecase from "../usecase";

export default class ProductRequestUsecase
  implements IProductRequestUsecase<CancelTokenSource> {
  private productRequestRepo: IProductRequestRepository<CancelTokenSource>;

  constructor(
    productRequestRepo: IProductRequestRepository<CancelTokenSource>
  ) {
    this.productRequestRepo = productRequestRepo;
  }

  async getProductRequest(
    pagination: IPagination,
    filter: PProductRequestFilter,
    sorts: ISort<EProductRequestSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IProductRequest[], IMeta]> {
    const [
      productRequests,
      meta,
    ] = await this.productRequestRepo.getProductRequest(
      pagination,
      filter,
      sorts,
      signal
    );
    return [productRequests, meta];
  }
}
