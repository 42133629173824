import "./index.css";

import { CssBaseline } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "material-ui-snackbar-provider";
import React from "react";
import ReactDOM from "react-dom";
import HttpsRedirect from "react-https-redirect";
import { Provider } from "react-redux";
import { ThroughProvider } from "react-through";
import { configureStore } from "src/redux";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const store = configureStore();

const theme = createMuiTheme({
  palette: {
    primary: { main: "#6495ed" },
  },
});

ReactDOM.render(
  <HttpsRedirect>
    <ThroughProvider>
      <SnackbarProvider SnackbarProps={{ autoHideDuration: 2000 }}>
        <MuiThemeProvider theme={theme}>
          <Provider store={store}>
            <CssBaseline />
            <App />
          </Provider>
        </MuiThemeProvider>
      </SnackbarProvider>
    </ThroughProvider>
  </HttpsRedirect>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
