import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";

import {
  EIdentificationSortField,
  IIdentification,
  PIdentificationFilter,
} from "../entities";
import IIdentificationRepository from "../repository";

export default class IdentificationRepository
  implements IIdentificationRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private IDENTIFICATION_ROUTE = "/admin/identification";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  buildFilter = (filter: PIdentificationFilter) => {
    return filter;
  };

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getIdentification(
    pagination: IPagination,
    filter: PIdentificationFilter,
    sorts: ISort<EIdentificationSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IIdentification[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      Boolean
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.IDENTIFICATION_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getIdentificationByUserId(userId: number): Promise<IIdentification> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.IDENTIFICATION_ROUTE}/${userId}`)
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async acceptIdentification(id: number): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(`${this.IDENTIFICATION_ROUTE}/${id}/approve`)
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async rejectIdentification(
    id: number,
    rejected_reason: string
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(
        `${this.IDENTIFICATION_ROUTE}/${id}/reject`,
        {
          rejected_reason,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
