import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import {
  ESpecialCategoryListSortField,
  ISpecialCategoryList,
  PSpecialCategoryListFilter,
} from "../entities";
import ISpecialCategoryListRepository from "../repository";

export default class SpecialCategoryListRepository
  implements ISpecialCategoryListRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private SPECIAL_CATEGORY_LIST_ROUTE = "/admin/special-category-list";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  private buildFilter(filter: PSpecialCategoryListFilter) {
    return filter;
  }

  async getSpecialCategoryList(
    specialCategoryId: number,
    pagination: IPagination,
    filter: PSpecialCategoryListFilter,
    sorts: ISort<ESpecialCategoryListSortField>[],
    signal: CancelTokenSource
  ): Promise<[ISpecialCategoryList[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        special_category_id: specialCategoryId,
        ...pagination,
        ...this.buildFilter(filter),
      },
      Boolean
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.SPECIAL_CATEGORY_LIST_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getSpecialCategoryListById(id: number): Promise<ISpecialCategoryList> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.SPECIAL_CATEGORY_LIST_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async createSpecialCategoryList(formData: FormData): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(this.SPECIAL_CATEGORY_LIST_ROUTE, formData)
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async updateSpecialCategoryList(
    id: number,
    formData: FormData
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(
        `${this.SPECIAL_CATEGORY_LIST_ROUTE}/${id}`,
        formData
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async deleteSpecialCategoryList(id: number): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().delete(
        `${this.SPECIAL_CATEGORY_LIST_ROUTE}/${id}`
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
