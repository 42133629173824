import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import { EPromoCodeSortField, IPromoCode, PPromoCodeFilter } from "../entities";
import IPromoCodeRepository from "../repository";

export default class PromoCodeRepository
  implements IPromoCodeRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private PROMO_CODE_ROUTE = "/admin/promo-code";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  private buildFilter(filter: PPromoCodeFilter) {
    return filter;
  }

  async getPromoCode(
    pagination: IPagination,
    filter: PPromoCodeFilter,
    sorts: ISort<EPromoCodeSortField>[],
    signal: CancelTokenSource
  ): Promise<[IPromoCode[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      { ...pagination, ...this.buildFilter(filter) },
      Boolean
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.PROMO_CODE_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  getPromoCodeById = async (id: number): Promise<IPromoCode> => {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.PROMO_CODE_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  };

  async createPromoCode(formData: FormData): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(this.PROMO_CODE_ROUTE, formData)
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async updatePromoCode(id: number, formData: FormData): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(`${this.PROMO_CODE_ROUTE}/${id}`, formData)
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async deletePromoCode(id: number): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().delete(`${this.PROMO_CODE_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
