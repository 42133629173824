import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import { EBannerSortField, IBanner, PBannerFilter } from "../entities";
import IBannerRepository from "../repository";

export default class BannerRepository
  implements IBannerRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private BANNER_ROUTE = "/admin/banner";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  buildFilter = (filter: PBannerFilter) => {
    const expired_at = filter.expired_at_enabled
      ? `${filter.expired_at_start},${filter.expired_at_end}`
      : undefined;

    const clonedFilter = { ...filter };
    delete clonedFilter.expired_at_enabled;
    delete clonedFilter.expired_at_start;
    delete clonedFilter.expired_at_end;

    return _.pickBy(
      {
        ...clonedFilter,
        expired_at,
      },
      Boolean
    );
  };

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getBanner(
    pagination: IPagination,
    filter: PBannerFilter,
    sorts: ISort<EBannerSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IBanner[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      (val) => val
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.BANNER_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getBannerById(id: number): Promise<IBanner> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.BANNER_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }
    const banner: IBanner = response.data;
    try {
      banner.actionPath = JSON.parse(banner.action_path);
    } catch (err) {}
    return banner;
  }

  async createBanner(formData: FormData): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(this.BANNER_ROUTE, formData)
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async updateBanner(id: number, formData: FormData): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(`${this.BANNER_ROUTE}/${id}`, formData)
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async deleteBanner(id: number): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().delete(`${this.BANNER_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
