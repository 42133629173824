import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import {
  EFeaturedProductSortField,
  IFeaturedProduct,
  PFeaturedProduct,
  PFeaturedProductFilter,
} from "../entities";
import IFeaturedProductRepository from "../repository";

export default class FeaturedProductRepository
  implements IFeaturedProductRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private FEATURED_PRODUCT_ROUTE = "/admin/featured-product";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  buildFilter = (filter: PFeaturedProductFilter) => {
    return filter;
  };

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getFeaturedProduct(
    pagination: IPagination,
    filter: PFeaturedProductFilter,
    sorts: ISort<EFeaturedProductSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IFeaturedProduct[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      (val) => val
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.FEATURED_PRODUCT_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getFeaturedProductById(id: number): Promise<IFeaturedProduct> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.FEATURED_PRODUCT_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }

    return response.data;
  }

  async createFeaturedProduct(
    featuredProduct: PFeaturedProduct
  ): Promise<void> {
    const [err, response] = await goPromise(
      this.apiClientFactory().post(this.FEATURED_PRODUCT_ROUTE, featuredProduct)
    );
    if (err) {
      throw this.getError(err);
    }

    return response.data;
  }

  async updateFeaturedProduct(
    id: number,
    featuredProduct: PFeaturedProduct
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(
        `${this.FEATURED_PRODUCT_ROUTE}/${id}`,
        featuredProduct
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async deleteFeaturedProduct(id: number): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().delete(`${this.FEATURED_PRODUCT_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
