import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EIdentificationSortField,
  IIdentification,
  PIdentificationFilter,
} from "../entities";
import IIdentificationRepository from "../repository";
import IIdentificationUsecase from "../usecase";

export default class IdentificationUsecase
  implements IIdentificationUsecase<CancelTokenSource> {
  private identificationRepo: IIdentificationRepository<CancelTokenSource>;

  constructor(
    identificationRepo: IIdentificationRepository<CancelTokenSource>
  ) {
    this.identificationRepo = identificationRepo;
  }

  async getIdentification(
    pagination: IPagination,
    filter: PIdentificationFilter,
    sorts: ISort<EIdentificationSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IIdentification[], IMeta]> {
    return await this.identificationRepo.getIdentification(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getIdentificationByUserId(userId: number): Promise<IIdentification> {
    return await this.identificationRepo.getIdentificationByUserId(userId);
  }

  async acceptIdentification(id: number): Promise<void> {
    await this.identificationRepo.acceptIdentification(id);
  }

  async rejectIdentification(
    id: number,
    rejected_reason: string
  ): Promise<void> {
    await this.identificationRepo.rejectIdentification(id, rejected_reason);
  }
}
