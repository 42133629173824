import BasicLink from "@delanobgt/admin-core/components/link/BasicLink";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import _ from "lodash";
import React from "react";
import useReactRouter from "use-react-router";
import { menuList } from "./menuList/constants";
import { IMenu } from "./menuList/types";

interface IProps {
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function MenuList({ setDrawerOpen }: IProps) {
  const { location } = useReactRouter();
  const PADDING_MULTIPLIER = 1.5;

  const [navState, setNavState] = React.useState<Record<string, boolean>>({});

  const toggleCollapse = React.useCallback(
    (stateName) => {
      console.log(navState, stateName, Boolean(stateName));
      const depth = Number(stateName.split("#")[1]);
      setNavState({
        ..._.mapValues(navState, (value, key) =>
          Number(key.split("#")[1]) >= depth ? false : value
        ),
        [stateName]: !Boolean(navState[stateName]),
      });
    },
    [navState, setNavState]
  );

  const renderMenu = React.useCallback(
    (menu: IMenu, path: string, depth: number) => {
      const { link, title, Icon, target, subMenus } = menu;
      if (target) {
        return (
          <ListItem
            button
            selected={location.pathname === link}
            onClick={() => {
              setDrawerOpen(false);
              window.open(link, target);
            }}
            style={{ paddingLeft: `${PADDING_MULTIPLIER * (depth + 1)}em` }}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={title} />
          </ListItem>
        );
      } else if (link) {
        return (
          <BasicLink
            key={title}
            to={link}
            disabled={location.pathname === link}
          >
            <ListItem
              button
              selected={location.pathname === link}
              onClick={() => setDrawerOpen(false)}
              style={{ paddingLeft: `${PADDING_MULTIPLIER * (depth + 1)}em` }}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={title} />
            </ListItem>
          </BasicLink>
        );
      } else if (subMenus) {
        const stateName = `${path}/${title}#${depth}`;
        return (
          <div key={title}>
            <ListItem
              button
              onClick={() => toggleCollapse(stateName)}
              style={{ paddingLeft: `${PADDING_MULTIPLIER * (depth + 1)}em` }}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={title} />
              {Boolean(navState[stateName]) ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </ListItem>

            <Collapse in={Boolean(navState[stateName])} timeout="auto">
              <List component="div" disablePadding>
                {menu.subMenus.map((subMenu) =>
                  renderMenu(subMenu, `${path}/${title}`, depth + 1)
                )}
              </List>
            </Collapse>
          </div>
        );
      }
    },
    [location.pathname, navState, toggleCollapse, setDrawerOpen]
  );

  const navList = React.useMemo(() => {
    return _.map(menuList, (menu) => renderMenu(menu, "", 0));
  }, [renderMenu]);

  return <List>{navList}</List>;
}
