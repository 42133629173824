import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";
import shallowDiff from "shallow-diff";

import {
  EFeaturedProductSortField,
  IFeaturedProduct,
  PFeaturedProduct,
  PFeaturedProductFilter,
} from "../entities";
import IFeaturedProductRepository from "../repository";
import IFeaturedProductUsecase from "../usecase";

export default class FeaturedProductUsecase
  implements IFeaturedProductUsecase<CancelTokenSource> {
  private featuredProductRepo: IFeaturedProductRepository<CancelTokenSource>;

  constructor(
    featuredProductRepo: IFeaturedProductRepository<CancelTokenSource>
  ) {
    this.featuredProductRepo = featuredProductRepo;
  }

  async getFeaturedProduct(
    pagination: IPagination,
    filter: PFeaturedProductFilter,
    sorts: ISort<EFeaturedProductSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IFeaturedProduct[], IMeta]> {
    return await this.featuredProductRepo.getFeaturedProduct(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getFeaturedProductById(id: number): Promise<IFeaturedProduct> {
    return await this.featuredProductRepo.getFeaturedProductById(id);
  }

  async createFeaturedProduct(
    featuredProduct: PFeaturedProduct
  ): Promise<void> {
    await this.featuredProductRepo.createFeaturedProduct(featuredProduct);
  }

  async updateFeaturedProduct(
    oldFeaturedProduct: PFeaturedProduct,
    newFeaturedProduct: PFeaturedProduct
  ): Promise<void> {
    const diffFeaturedProduct = _.pick(
      newFeaturedProduct,
      shallowDiff(oldFeaturedProduct, newFeaturedProduct).updated
    ) as PFeaturedProduct;

    await this.featuredProductRepo.updateFeaturedProduct(
      oldFeaturedProduct.id,
      diffFeaturedProduct
    );
  }

  async deleteFeaturedProduct(id: number): Promise<void> {
    await this.featuredProductRepo.deleteFeaturedProduct(id);
  }
}
