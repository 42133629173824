import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  ESpecialCategoryListSortField,
  ISpecialCategoryList,
  PSpecialCategoryList,
  PSpecialCategoryListFilter,
} from "../entities";
import ISpecialCategoryListRepository from "../repository";
import ISpecialCategoryListUsecase from "../usecase";

export default class SpecialCategoryListUsecase
  implements ISpecialCategoryListUsecase<CancelTokenSource> {
  private specialCategoryListRepo: ISpecialCategoryListRepository<
    CancelTokenSource
  >;

  constructor(
    specialCategoryListRepo: ISpecialCategoryListRepository<CancelTokenSource>
  ) {
    this.specialCategoryListRepo = specialCategoryListRepo;
  }

  async getSpecialCategoryList(
    specialCategoryId: number,
    pagination: IPagination,
    filter: PSpecialCategoryListFilter,
    sorts: ISort<ESpecialCategoryListSortField>[],
    signal: CancelTokenSource
  ): Promise<[ISpecialCategoryList[], IMeta]> {
    return await this.specialCategoryListRepo.getSpecialCategoryList(
      specialCategoryId,
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getSpecialCategoryListById(id: number): Promise<ISpecialCategoryList> {
    return await this.specialCategoryListRepo.getSpecialCategoryListById(id);
  }

  async createSpecialCategoryList(
    specialCategoryList: PSpecialCategoryList,
    image: any
  ): Promise<void> {
    const formData = new FormData();
    formData.append("name", String(specialCategoryList.name));
    formData.append("priority", String(specialCategoryList.priority));
    formData.append("published", String(specialCategoryList.published));
    formData.append(
      "product_brand_id",
      String(specialCategoryList.product_brand_id)
    );
    formData.append(
      "special_category_id",
      String(specialCategoryList.special_category_id)
    );
    formData.append("image", image);

    await this.specialCategoryListRepo.createSpecialCategoryList(formData);
  }

  async updateSpecialCategoryList(
    oldSpecialCategoryList: PSpecialCategoryList,
    newSpecialCategoryList: PSpecialCategoryList,
    image: any
  ): Promise<void> {
    const formData = new FormData();
    formData.append("name", String(newSpecialCategoryList.name));
    formData.append("priority", String(newSpecialCategoryList.priority));
    formData.append("published", String(newSpecialCategoryList.published));
    formData.append(
      "product_brand_id",
      String(newSpecialCategoryList.product_brand_id)
    );
    if (image) formData.append("image", image);

    await this.specialCategoryListRepo.updateSpecialCategoryList(
      oldSpecialCategoryList.id,
      formData
    );
  }

  async deleteSpecialCategoryList(id: number): Promise<void> {
    await this.specialCategoryListRepo.deleteSpecialCategoryList(id);
  }
}
