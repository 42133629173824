import CrossStorage from "@delanobgt/admin-core/apis/crossStorage";
import { LocalStorageKeys } from "@delanobgt/admin-core/util/contants";
import axios, { AxiosRequestConfig } from "axios";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";

axios.defaults.timeout = 60 * 4 * 1000;

const celestineClientFactory: ApiClientFactory = (
  config?: AxiosRequestConfig
) => {
  return axios.create({
    baseURL: process.env.REACT_APP_CELESTINE_API_BASE_URL,
    headers: {
      authorization:
        "Bearer " + CrossStorage.getInstance().get(LocalStorageKeys.AUTH_TOKEN),
    },
    ...config,
  });
};

export default celestineClientFactory;
