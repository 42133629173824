import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EReferralRewardSortField,
  IReferralReward,
  PReferralRewardFilter,
} from "../entities";
import IReferralRewardRepository from "../repository";
import IReferralRewardUsecase from "../usecase";

export default class ReferralRewardUsecase
  implements IReferralRewardUsecase<CancelTokenSource> {
  private referralRewardRepo: IReferralRewardRepository<CancelTokenSource>;

  constructor(
    referralRewardRepo: IReferralRewardRepository<CancelTokenSource>
  ) {
    this.referralRewardRepo = referralRewardRepo;
  }

  async getReferralRewardByUserId(
    userId: number,
    pagination: IPagination,
    filter: PReferralRewardFilter,
    sorts: ISort<EReferralRewardSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IReferralReward[], IMeta]> {
    return await this.referralRewardRepo.getReferralRewardByUserId(
      userId,
      pagination,
      filter,
      sorts,
      signal
    );
  }
}
