import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import {
  EProductRequestSortField,
  IProductRequest,
  PProductRequestFilter,
} from "../entities";
import IProductRequestRepository from "../repository";

export default class ProductRequestRepository
  implements IProductRequestRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private PRODUCT_REQUEST_ROUTE = "/admin/product-request";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private buildFilter(filter: PProductRequestFilter) {
    return filter;
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getProductRequest(
    pagination: IPagination,
    filter: PProductRequestFilter,
    sorts: ISort<EProductRequestSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IProductRequest[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      (val) => val
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.PRODUCT_REQUEST_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }
}
