import { IProductSize } from "../entities";
import IProductSizeRepository from "../repository";
import IProductSizeUsecase from "../usecase";

export default class ProductSizeUsecase implements IProductSizeUsecase {
  private productSizeRepo: IProductSizeRepository;

  constructor(productSizeRepo: IProductSizeRepository) {
    this.productSizeRepo = productSizeRepo;
  }

  async getProductSizeByProductCategoryId(
    productSizeId: number
  ): Promise<IProductSize[]> {
    return await this.productSizeRepo.getProductSizeByProductCategoryId(
      productSizeId
    );
  }

  async createProductSize(productSize: IProductSize): Promise<void> {
    await this.productSizeRepo.createProductSize(productSize);
  }

  async updateProductSize(productSize: IProductSize): Promise<void> {
    await this.productSizeRepo.updateProductSize(productSize);
  }
}
