import { goPromise } from "@delanobgt/admin-core/util/helpers";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import { ICounter, IFund, IGeneralStatistic } from "../entities";
import IStatisticRepository from "../repository";

export default class StatisticRepository
  implements IStatisticRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private STATISTIC_ROUTE = "/admin/statistic";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getFund(signal: CancelTokenSource): Promise<IFund> {
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.STATISTIC_ROUTE}/fund`
      )
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async getGeneralStatistic(
    signal: CancelTokenSource
  ): Promise<IGeneralStatistic> {
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        this.STATISTIC_ROUTE
      )
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async getCounter(signal: CancelTokenSource): Promise<ICounter> {
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.STATISTIC_ROUTE}/counter`
      )
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async getNewsletterExcelData(month: string, year: string): Promise<any> {
    const [err, response] = await goPromise(
      this.apiClientFactory({
        responseType: "blob",
      }).get(`${this.STATISTIC_ROUTE}/newsletter/download`, {
        params: { month, year },
      })
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }
}
