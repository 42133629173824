import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import { ERevenueSortField, IRevenue, PRevenueFilter } from "../entities";
import IRevenueRepository from "../repository";
import IRevenueUsecase from "../usecase";

export default class RevenueUsecase
  implements IRevenueUsecase<CancelTokenSource> {
  private revenueRepo: IRevenueRepository<CancelTokenSource>;

  constructor(revenueRepo: IRevenueRepository<CancelTokenSource>) {
    this.revenueRepo = revenueRepo;
  }

  async getRevenue(
    pagination: IPagination,
    filter: PRevenueFilter,
    sorts: ISort<ERevenueSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IRevenue[], IMeta]> {
    const [revenues, meta] = await this.revenueRepo.getRevenue(
      pagination,
      filter,
      sorts,
      signal
    );
    return [revenues, meta];
  }
}
