import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EBnibTransactionSortField,
  IBnibTransaction,
  PBnibTransactionFilter,
} from "../entities";
import IBnibTransactionRepository from "../repository";
import IBnibTransactionUsecase from "../usecase";

export default class BnibTransactionUsecase
  implements IBnibTransactionUsecase<CancelTokenSource> {
  private bnibTransactionRepo: IBnibTransactionRepository<CancelTokenSource>;

  constructor(
    bnibTransactionRepo: IBnibTransactionRepository<CancelTokenSource>
  ) {
    this.bnibTransactionRepo = bnibTransactionRepo;
  }

  async getBnibTransaction(
    pagination: IPagination,
    filter: PBnibTransactionFilter,
    sorts: ISort<EBnibTransactionSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IBnibTransaction[], IMeta]> {
    return await this.bnibTransactionRepo.getBnibTransaction(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getBnibTransactionByCode(code: string): Promise<IBnibTransaction> {
    return await this.bnibTransactionRepo.getBnibTransactionByCode(code);
  }

  async arriveBnibTransaction(code: string): Promise<void> {
    await this.bnibTransactionRepo.arriveBnibTransaction(code);
  }

  async acceptBnibTransaction(code: string): Promise<void> {
    await this.bnibTransactionRepo.acceptBnibTransaction(code);
  }

  async rejectBnibTransaction(code: string, reason: string): Promise<void> {
    await this.bnibTransactionRepo.rejectBnibTransaction(code, reason);
  }

  async refundBnibTransaction(
    code: string,
    courier_slug: string,
    tracking_code: string
  ): Promise<void> {
    await this.bnibTransactionRepo.refundBnibTransaction(
      code,
      courier_slug,
      tracking_code
    );
  }

  async defectBnibTransaction(
    code: string,
    defect: boolean,
    images?: any[]
  ): Promise<void> {
    const formData = new FormData();
    formData.append("is_defect", String(defect));
    if (defect) {
      for (let image of images) {
        formData.append("defect_images[]", image);
      }
    }

    await this.bnibTransactionRepo.defectBnibTransaction(code, formData);
  }

  async legitCheckBnibTransaction(code: string, result: string): Promise<void> {
    await this.bnibTransactionRepo.legitCheckBnibTransaction(code, result);
  }

  async sendBnibTransaction(
    code: string,
    courier_slug: string,
    tracking_code: string,
    shipping_cost: number
  ): Promise<void> {
    await this.bnibTransactionRepo.sendBnibTransaction(
      code,
      courier_slug,
      tracking_code,
      shipping_cost
    );
  }

  async disputeBnibTransaction(
    code: string,
    reject_reason: string
  ): Promise<void> {
    await this.bnibTransactionRepo.disputeBnibTransaction(code, reject_reason);
  }
}
