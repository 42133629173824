import { ApiClientFactory } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import celestineClientFactory from "src/apis/celestine";

import IAdminUserRepository from "./adminUser/repository";
import AdminUserRepository from "./adminUser/repository/repository";
import IAdminUserUsecase from "./adminUser/usecase";
import AdminUserUsecase from "./adminUser/usecase/usecase";
import IAuthRepository from "./auth/repository";
import AuthRepository from "./auth/repository/repository";
import IAuthUsecase from "./auth/usecase";
import AuthUsecase from "./auth/usecase/usecase";
import IBannerRepository from "./banner/repository";
import BannerRepository from "./banner/repository/repository";
import IBannerUsecase from "./banner/usecase";
import BannerUsecase from "./banner/usecase/usecase";
import IBnibBuyOrderRepository from "./bnibBuyOrder/repository";
import BnibBuyOrderRepository from "./bnibBuyOrder/repository/repository";
import IBnibBuyOrderUsecase from "./bnibBuyOrder/usecase";
import BnibBuyOrderUsecase from "./bnibBuyOrder/usecase/usecase";
import IBnibProductRepository from "./bnibProduct/repository";
import BnibProductRepository from "./bnibProduct/repository/repository";
import IBnibProductUsecase from "./bnibProduct/usecase";
import BnibProductUsecase from "./bnibProduct/usecase/usecase";
import IBnibTransactionRepository from "./bnibTransaction/repository";
import BnibTransactionRepository from "./bnibTransaction/repository/repository";
import IBnibTransactionUsecase from "./bnibTransaction/usecase";
import BnibTransactionUsecase from "./bnibTransaction/usecase/usecase";
import IConfigRepository from "./config/repository";
import ConfigRepository from "./config/repository/repository";
import IConfigUsecase from "./config/usecase";
import ConfigUsecase from "./config/usecase/usecase";
import IDepositFeeRepository from "./depositFee/repository";
import DepositFeeRepository from "./depositFee/repository/repository";
import IDepositFeeUsecase from "./depositFee/usecase";
import DepositFeeUsecase from "./depositFee/usecase/usecase";
import IFeaturedProductRepository from "./featuredProduct/repository";
import FeaturedProductRepository from "./featuredProduct/repository/repository";
import IFeaturedProductUsecase from "./featuredProduct/usecase";
import FeaturedProductUsecase from "./featuredProduct/usecase/usecase";
import IFloatingFundRepository from "./floatingFund/repository";
import FloatingFundRepository from "./floatingFund/repository/repository";
import IFloatingFundUsecase from "./floatingFund/usecase";
import FloatingFundUsecase from "./floatingFund/usecase/usecase";
import IIdentificationRepository from "./identification/repository";
import IdentificationRepository from "./identification/repository/repository";
import IIdentificationUsecase from "./identification/usecase";
import IdentificationUsecase from "./identification/usecase/usecase";
import ILegitCheckRepository from "./legitCheck/repository";
import LegitCheckRepository from "./legitCheck/repository/repository";
import ILegitCheckUsecase from "./legitCheck/usecase";
import LegitCheckUsecase from "./legitCheck/usecase/usecase";
import IProductRepository from "./product/repository";
import ProductRepository from "./product/repository/repository";
import IProductUsecase from "./product/usecase";
import ProductUsecase from "./product/usecase/usecase";
import IProductBrandRepository from "./productBrand/repository";
import ProductBrandRepository from "./productBrand/repository/repository";
import IProductBrandUsecase from "./productBrand/usecase";
import ProductBrandUsecase from "./productBrand/usecase/usecase";
import IProductCategoryRepository from "./productCategory/repository";
import ProductCategoryRepository from "./productCategory/repository/repository";
import IProductCategoryUsecase from "./productCategory/usecase";
import ProductCategoryUsecase from "./productCategory/usecase/usecase";
import IProductRequestRepository from "./productRequest/repository";
import ProductRequestRepository from "./productRequest/repository/repository";
import IProductRequestUsecase from "./productRequest/usecase";
import ProductRequestUsecase from "./productRequest/usecase/usecase";
import IProductSizeRepository from "./productSize/repository";
import ProductSizeRepository from "./productSize/repository/repository";
import IProductSizeUsecase from "./productSize/usecase";
import ProductSizeUsecase from "./productSize/usecase/usecase";
import IPromoCodeRepository from "./promoCode/repository";
import PromoCodeRepository from "./promoCode/repository/repository";
import IPromoCodeUsecase from "./promoCode/usecase";
import PromoCodeUsecase from "./promoCode/usecase/usecase";
import IRevenueRepository from "./revenue/repository";
import RevenueRepository from "./revenue/repository/repository";
import IRevenueUsecase from "./revenue/usecase";
import RevenueUsecase from "./revenue/usecase/usecase";
import IShippingAddressRepository from "./shippingAddress/repository";
import ShippingAddressRepository from "./shippingAddress/repository/repository";
import IShippingAddressUsecase from "./shippingAddress/usecase";
import ShippingAddressUsecase from "./shippingAddress/usecase/usecase";
import ISpecialCategoryRepository from "./specialCategory/repository";
import SpecialCategoryRepository from "./specialCategory/repository/repository";
import ISpecialCategoryUsecase from "./specialCategory/usecase";
import SpecialCategoryUsecase from "./specialCategory/usecase/usecase";
import ISpecialCategoryListRepository from "./specialCategoryList/repository";
import SpecialCategoryListRepository from "./specialCategoryList/repository/repository";
import ISpecialCategoryListUsecase from "./specialCategoryList/usecase";
import SpecialCategoryListUsecase from "./specialCategoryList/usecase/usecase";
import IStatisticRepository from "./statistic/repository";
import StatisticRepository from "./statistic/repository/repository";
import IStatisticUsecase from "./statistic/usecase";
import StatisticUsecase from "./statistic/usecase/usecase";
import ITopUpRepository from "./topUp/repository";
import TopUpRepository from "./topUp/repository/repository";
import ITopUpUsecase from "./topUp/usecase";
import TopUpUsecase from "./topUp/usecase/usecase";
import IUserRepository from "./user/repository";
import UserRepository from "./user/repository/repository";
import IUserUsecase from "./user/usecase";
import UserUsecase from "./user/usecase/usecase";

import IReferralRewardRepository from "./referralReward/repository";
import ReferralRewardRepository from "./referralReward/repository/repository";
import IReferralRewardUsecase from "./referralReward/usecase";
import ReferralRewardUsecase from "./referralReward/usecase/usecase";

import IWalletMutationRepository from "./walletMutation/repository";
import WalletMutationRepository from "./walletMutation/repository/repository";
import IWalletMutationUsecase from "./walletMutation/usecase";
import WalletMutationUsecase from "./walletMutation/usecase/usecase";
import IWithdrawRequestRepository from "./withdrawRequest/repository";
import WithdrawRequestRepository from "./withdrawRequest/repository/repository";
import WithdrawRequestUsecase from "./withdrawRequest/usecase/usecase";
import IProductCategoryGroupRepository from "./productCategoryGroup/repository";
import ProductCategoryGroupRepository from "./productCategoryGroup/repository/repository";
import IProductCategoryGroupUsecase from "./productCategoryGroup/usecase";
import ProductCategoryGroupUsecase from "./productCategoryGroup/usecase/usecase";

interface IRepositories {
  adminUserRepository: IAdminUserRepository<CancelTokenSource>;
  authRepository: IAuthRepository;
  bannerRepository: IBannerRepository<CancelTokenSource>;
  bnibBuyOrderRepository: IBnibBuyOrderRepository<CancelTokenSource>;
  bnibProductRepository: IBnibProductRepository<CancelTokenSource>;
  bnibTransactionRepository: IBnibTransactionRepository<CancelTokenSource>;
  configRepository: IConfigRepository<CancelTokenSource>;
  depositFeeRepository: IDepositFeeRepository<CancelTokenSource>;
  featuredProductRepository: IFeaturedProductRepository<CancelTokenSource>;
  floatingFundRepository: IFloatingFundRepository<CancelTokenSource>;
  identificationRepository: IIdentificationRepository<CancelTokenSource>;
  legitCheckRepository: ILegitCheckRepository;
  productRepository: IProductRepository<CancelTokenSource>;
  productBrandRepository: IProductBrandRepository<CancelTokenSource>;
  productCategoryRepository: IProductCategoryRepository<CancelTokenSource>;
  productCategoryGroupRepository: IProductCategoryGroupRepository<
    CancelTokenSource
  >;
  productRequestRepository: IProductRequestRepository<CancelTokenSource>;
  productSizeRepository: IProductSizeRepository;
  promoCodeRepository: IPromoCodeRepository<CancelTokenSource>;
  referralRewardRepository: IReferralRewardRepository<CancelTokenSource>;
  revenueRepository: IRevenueRepository<CancelTokenSource>;
  shippingAddressRepository: IShippingAddressRepository;
  specialCategoryRepository: ISpecialCategoryRepository<CancelTokenSource>;
  specialCategoryListRepository: ISpecialCategoryListRepository<
    CancelTokenSource
  >;
  statisticRepository: IStatisticRepository<CancelTokenSource>;
  topUpRepository: ITopUpRepository<CancelTokenSource>;
  userRepository: IUserRepository<CancelTokenSource>;
  walletMutationRepository: IWalletMutationRepository<CancelTokenSource>;
  withdrawRequestRepository: IWithdrawRequestRepository<CancelTokenSource>;
}

function initRepositories(
  celestineClientFactory: ApiClientFactory
): IRepositories {
  return {
    adminUserRepository: new AdminUserRepository(celestineClientFactory),
    authRepository: new AuthRepository(celestineClientFactory),
    bannerRepository: new BannerRepository(celestineClientFactory),
    bnibBuyOrderRepository: new BnibBuyOrderRepository(celestineClientFactory),
    bnibProductRepository: new BnibProductRepository(celestineClientFactory),
    bnibTransactionRepository: new BnibTransactionRepository(
      celestineClientFactory
    ),
    configRepository: new ConfigRepository(celestineClientFactory),
    depositFeeRepository: new DepositFeeRepository(celestineClientFactory),
    featuredProductRepository: new FeaturedProductRepository(
      celestineClientFactory
    ),
    floatingFundRepository: new FloatingFundRepository(celestineClientFactory),
    identificationRepository: new IdentificationRepository(
      celestineClientFactory
    ),
    legitCheckRepository: new LegitCheckRepository(celestineClientFactory),
    productRepository: new ProductRepository(celestineClientFactory),
    productBrandRepository: new ProductBrandRepository(celestineClientFactory),
    productCategoryRepository: new ProductCategoryRepository(
      celestineClientFactory
    ),
    productCategoryGroupRepository: new ProductCategoryGroupRepository(
      celestineClientFactory
    ),
    productRequestRepository: new ProductRequestRepository(
      celestineClientFactory
    ),
    productSizeRepository: new ProductSizeRepository(celestineClientFactory),
    promoCodeRepository: new PromoCodeRepository(celestineClientFactory),
    referralRewardRepository: new ReferralRewardRepository(
      celestineClientFactory
    ),
    revenueRepository: new RevenueRepository(celestineClientFactory),
    shippingAddressRepository: new ShippingAddressRepository(
      celestineClientFactory
    ),
    specialCategoryRepository: new SpecialCategoryRepository(
      celestineClientFactory
    ),
    specialCategoryListRepository: new SpecialCategoryListRepository(
      celestineClientFactory
    ),
    statisticRepository: new StatisticRepository(celestineClientFactory),
    topUpRepository: new TopUpRepository(celestineClientFactory),
    userRepository: new UserRepository(celestineClientFactory),
    walletMutationRepository: new WalletMutationRepository(
      celestineClientFactory
    ),
    withdrawRequestRepository: new WithdrawRequestRepository(
      celestineClientFactory
    ),
  };
}

interface IUsecases {
  adminUser: IAdminUserUsecase<CancelTokenSource>;
  auth: IAuthUsecase;
  banner: IBannerUsecase<CancelTokenSource>;
  bnibBuyOrder: IBnibBuyOrderUsecase<CancelTokenSource>;
  bnibProduct: IBnibProductUsecase<CancelTokenSource>;
  bnibTransaction: IBnibTransactionUsecase<CancelTokenSource>;
  config: IConfigUsecase<CancelTokenSource>;
  depositFee: IDepositFeeUsecase<CancelTokenSource>;
  featuredProduct: IFeaturedProductUsecase<CancelTokenSource>;
  floatingFund: IFloatingFundUsecase<CancelTokenSource>;
  identification: IIdentificationUsecase<CancelTokenSource>;
  legitCheck: ILegitCheckUsecase;
  product: IProductUsecase<CancelTokenSource>;
  productBrand: IProductBrandUsecase<CancelTokenSource>;
  productCategory: IProductCategoryUsecase<CancelTokenSource>;
  productCategoryGroup: IProductCategoryGroupUsecase<CancelTokenSource>;
  productRequest: IProductRequestUsecase<CancelTokenSource>;
  productSize: IProductSizeUsecase;
  promoCode: IPromoCodeUsecase<CancelTokenSource>;
  referralReward: IReferralRewardUsecase<CancelTokenSource>;
  revenue: IRevenueUsecase<CancelTokenSource>;
  shippingAddress: IShippingAddressUsecase;
  specialCategory: ISpecialCategoryUsecase<CancelTokenSource>;
  specialCategoryList: ISpecialCategoryListUsecase<CancelTokenSource>;
  statistic: IStatisticUsecase<CancelTokenSource>;
  topUp: ITopUpUsecase<CancelTokenSource>;
  user: IUserUsecase<CancelTokenSource>;
  walletMutation: IWalletMutationUsecase<CancelTokenSource>;
  withdrawRequest: IWithdrawRequestRepository<CancelTokenSource>;
}

function initUsecases(repos: IRepositories): IUsecases {
  return {
    adminUser: new AdminUserUsecase(repos.adminUserRepository),
    auth: new AuthUsecase(repos.authRepository),
    banner: new BannerUsecase(repos.bannerRepository),
    bnibBuyOrder: new BnibBuyOrderUsecase(repos.bnibBuyOrderRepository),
    bnibProduct: new BnibProductUsecase(repos.bnibProductRepository),
    bnibTransaction: new BnibTransactionUsecase(
      repos.bnibTransactionRepository
    ),
    config: new ConfigUsecase(repos.configRepository),
    depositFee: new DepositFeeUsecase(repos.depositFeeRepository),
    featuredProduct: new FeaturedProductUsecase(
      repos.featuredProductRepository
    ),
    floatingFund: new FloatingFundUsecase(repos.floatingFundRepository),
    identification: new IdentificationUsecase(repos.identificationRepository),
    legitCheck: new LegitCheckUsecase(repos.legitCheckRepository),
    product: new ProductUsecase(repos.productRepository),
    productBrand: new ProductBrandUsecase(repos.productBrandRepository),
    productCategory: new ProductCategoryUsecase(
      repos.productCategoryRepository
    ),
    productCategoryGroup: new ProductCategoryGroupUsecase(
      repos.productCategoryGroupRepository
    ),
    productRequest: new ProductRequestUsecase(repos.productRequestRepository),
    productSize: new ProductSizeUsecase(repos.productSizeRepository),
    promoCode: new PromoCodeUsecase(repos.promoCodeRepository),
    referralReward: new ReferralRewardUsecase(repos.referralRewardRepository),
    revenue: new RevenueUsecase(repos.revenueRepository),
    shippingAddress: new ShippingAddressUsecase(
      repos.shippingAddressRepository
    ),
    specialCategory: new SpecialCategoryUsecase(
      repos.specialCategoryRepository
    ),
    specialCategoryList: new SpecialCategoryListUsecase(
      repos.specialCategoryListRepository
    ),
    statistic: new StatisticUsecase(repos.statisticRepository),
    topUp: new TopUpUsecase(repos.topUpRepository),
    user: new UserUsecase(repos.userRepository),
    walletMutation: new WalletMutationUsecase(repos.walletMutationRepository),
    withdrawRequest: new WithdrawRequestUsecase(
      repos.withdrawRequestRepository
    ),
  };
}

const repos = initRepositories(celestineClientFactory);
const Interactors: IUsecases = initUsecases(repos);

export default Interactors;
