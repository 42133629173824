import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EWithdrawRequestSortField,
  IWithdrawRequest,
  PWithdrawRequestFilter,
} from "../entities";
import IWithdrawRequestRepository from "../repository";
import IWithdrawRequestUsecase from "../usecase";

export default class WithdrawRequestUsecase
  implements IWithdrawRequestUsecase<CancelTokenSource> {
  private withdrawRequestRepo: IWithdrawRequestRepository<CancelTokenSource>;

  constructor(
    withdrawRequestRepo: IWithdrawRequestRepository<CancelTokenSource>
  ) {
    this.withdrawRequestRepo = withdrawRequestRepo;
  }

  async getWithdrawRequest(
    pagination: IPagination,
    filter: PWithdrawRequestFilter,
    sorts: ISort<EWithdrawRequestSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IWithdrawRequest[], IMeta]> {
    return await this.withdrawRequestRepo.getWithdrawRequest(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getWithdrawRequestById(id: number): Promise<IWithdrawRequest> {
    return await this.withdrawRequestRepo.getWithdrawRequestById(id);
  }

  async approveWithdrawRequest(
    id: number,
    transaction_code: string
  ): Promise<void> {
    await this.withdrawRequestRepo.approveWithdrawRequest(id, transaction_code);
  }

  async rejectWithdrawRequest(
    id: number,
    rejected_reason: string
  ): Promise<void> {
    await this.withdrawRequestRepo.rejectWithdrawRequest(id, rejected_reason);
  }
}
