import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import {
  ESpecialCategorySortField,
  ISpecialCategory,
  PSpecialCategory,
  PSpecialCategoryFilter,
} from "../entities";
import ISpecialCategoryRepository from "../repository";

export default class SpecialCategoryRepository
  implements ISpecialCategoryRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private SPECIAL_CATEGORY_ROUTE = "/admin/special-category";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  private buildFilter(filter: PSpecialCategoryFilter) {
    return filter;
  }

  async getSpecialCategory(
    pagination: IPagination,
    filter: PSpecialCategoryFilter,
    sorts: ISort<ESpecialCategorySortField>[],
    signal: CancelTokenSource
  ): Promise<[ISpecialCategory[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      { ...pagination, ...this.buildFilter(filter) },
      Boolean
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.SPECIAL_CATEGORY_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getSpecialCategoryById(id: number): Promise<ISpecialCategory> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.SPECIAL_CATEGORY_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async createSpecialCategory(
    specialCategory: PSpecialCategory
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(this.SPECIAL_CATEGORY_ROUTE, specialCategory)
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async updateSpecialCategory(
    id: number,
    specialCategory: PSpecialCategory
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(
        `${this.SPECIAL_CATEGORY_ROUTE}/${id}`,
        specialCategory
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async deleteSpecialCategory(id: number): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().delete(`${this.SPECIAL_CATEGORY_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
