import { CancelTokenSource } from "axios";

import { IAdminUser, INewAdminUser, PNewAdminUser, Role } from "../entities";
import IAdminUserRepository from "../repository";
import IAdminUserUsecase from "../usecase";

export default class AdminUserUsecase
  implements IAdminUserUsecase<CancelTokenSource> {
  private adminUserRepo: IAdminUserRepository<CancelTokenSource>;

  constructor(adminUserRepo: IAdminUserRepository<CancelTokenSource>) {
    this.adminUserRepo = adminUserRepo;
  }

  async getAdminUser(signal: CancelTokenSource): Promise<IAdminUser[]> {
    return await this.adminUserRepo.getAdminUser(signal);
  }

  async getAdminUserById(id: number): Promise<IAdminUser> {
    return await this.adminUserRepo.getAdminUserById(id);
  }

  async createAdminUser(adminUser: INewAdminUser): Promise<void> {
    await this.adminUserRepo.createAdminUser(adminUser);
  }

  async updateAdminUser(id: number, adminUser: PNewAdminUser): Promise<void> {
    await this.adminUserRepo.updateAdminUser(id, adminUser);
  }

  async deleteAdminUser(id: number): Promise<void> {
    await this.adminUserRepo.deleteAdminUser(id);
  }

  async getAdminUserRole(): Promise<Role[]> {
    return await this.adminUserRepo.getAdminUserRole();
  }
}
