import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EWalletMutationSortField,
  IWalletMutation,
  PWalletMutationFilter,
} from "../entities";
import IWalletMutationRepository from "../repository";
import IWalletMutationUsecase from "../usecase";

export default class WalletMutationUsecase
  implements IWalletMutationUsecase<CancelTokenSource> {
  private walletMutationRepo: IWalletMutationRepository<CancelTokenSource>;

  constructor(
    walletMutationRepo: IWalletMutationRepository<CancelTokenSource>
  ) {
    this.walletMutationRepo = walletMutationRepo;
  }

  async getWalletMutationByUserId(
    userId: number,
    pagination: IPagination,
    filter: PWalletMutationFilter,
    sorts: ISort<EWalletMutationSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IWalletMutation[], IMeta]> {
    return await this.walletMutationRepo.getWalletMutationByUserId(
      userId,
      pagination,
      filter,
      sorts,
      signal
    );
  }
}
