import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import { ETopUpSortField, ITopUp, PTopUpFilter } from "../entities";
import ITopUpRepository from "../repository";
import ITopUpUsecase from "../usecase";

export default class TopUpUsecase implements ITopUpUsecase<CancelTokenSource> {
  private topUpRepo: ITopUpRepository<CancelTokenSource>;

  constructor(topUpRepo: ITopUpRepository<CancelTokenSource>) {
    this.topUpRepo = topUpRepo;
  }

  async getTopUp(
    pagination: IPagination,
    filter: PTopUpFilter,
    sorts: ISort<ETopUpSortField>[],
    signal?: CancelTokenSource
  ): Promise<[ITopUp[], IMeta]> {
    return await this.topUpRepo.getTopUp(pagination, filter, sorts, signal);
  }

  async getTopUpById(id: number): Promise<ITopUp> {
    return await this.topUpRepo.getTopUpById(id);
  }
}
