import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EFloatingFundSortField,
  IFloatingFund,
  PFloatingFundFilter,
} from "../entities";
import IFloatingFundRepository from "../repository";
import IFloatingFundUsecase from "../usecase";

export default class FloatingFundUsecase
  implements IFloatingFundUsecase<CancelTokenSource> {
  private floatingFundRepo: IFloatingFundRepository<CancelTokenSource>;

  constructor(floatingFundRepo: IFloatingFundRepository<CancelTokenSource>) {
    this.floatingFundRepo = floatingFundRepo;
  }

  async getFloatingFund(
    pagination: IPagination,
    filter: PFloatingFundFilter,
    sorts: ISort<EFloatingFundSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IFloatingFund[], IMeta]> {
    return await this.floatingFundRepo.getFloatingFund(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getFloatingFundExcelData(): Promise<any> {
    return await this.floatingFundRepo.getFloatingFundExcelData();
  }
}
