import { produce } from "immer";
import { Reducer } from "redux";
import {
  ICounter,
  IFund,
  IGeneralStatistic,
} from "src/core/statistic/entities";
import { ActionType, createAction, getType } from "typesafe-actions";

/*
 * state
 */
export interface IStatisticState {
  fund: IFund;
  generalStatistic: IGeneralStatistic;
  counter: ICounter;
  loading: boolean;
  error: any;
}

const initialState: IStatisticState = {
  fund: null,
  generalStatistic: null,
  counter: null,
  loading: true,
  error: null,
};

/*
 * action types
 */
const FETCH_REQUESTED = "statistic/FETCH_REQUESTED";
const FETCH_SUCCEEDED = "statistic/FETCH_SUCCEEDED";
const FETCH_FAILED = "statistic/FETCH_FAILED";
const POLLING_START_REQUESTED = "statistic/POLLING_START_REQUESTED";
const POLLING_STOP_REQUESTED = "statistic/POLLING_STOP_REQUESTED";

/*
 * action creators
 */
export const actions = {
  fetchRequested: createAction(FETCH_REQUESTED)(),

  fetchSucceeded: createAction(FETCH_SUCCEEDED)<{
    fund: IFund;
    generalStatistic: IGeneralStatistic;
    counter: ICounter;
  }>(),

  fetchFailed: createAction(FETCH_FAILED)<any>(),

  pollingStartRequested: createAction(POLLING_START_REQUESTED)<{
    refreshDelay: number;
  }>(),

  pollingStopRequested: createAction(POLLING_STOP_REQUESTED)(),
};
export type TActions = ActionType<typeof actions>;

const reducer: Reducer<IStatisticState, TActions> = (
  state = initialState,
  action
) => {
  return produce<IStatisticState>(state, (draft) => {
    switch (action.type) {
      case getType(actions.fetchRequested): {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case getType(actions.fetchSucceeded): {
        const {
          payload: { fund, generalStatistic, counter },
        } = action;
        draft.fund = fund;
        draft.generalStatistic = generalStatistic;
        draft.counter = counter;
        draft.loading = false;
        draft.error = null;
        break;
      }
      case getType(actions.fetchFailed): {
        const { payload } = action;
        draft.loading = false;
        draft.error = payload;
        break;
      }
    }
  });
};

export default reducer;
