import NotificationPane from "@delanobgt/admin-core/components/card/NotificationPane";
import CommonError from "@delanobgt/admin-core/components/error/CommonError";
import CircleProgress from "@delanobgt/admin-core/components/suspense/CircleProgress";
import Fetch from "@delanobgt/admin-core/components/suspense/Fetch";
import useIntervalRun from "@delanobgt/admin-core/hooks/useIntervalRun";
import { Divider, makeStyles, Popover } from "@material-ui/core";
import {
  People as PeopleIcon,
  Receipt as ReceiptIcon,
  SvgIconComponent,
  VerticalAlignTop as VerticalAlignTopIcon,
} from "@material-ui/icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ICounter } from "src/core/statistic/entities";
import { RootState } from "src/redux";
import { adminRoutes } from "src/Router/router/routes/loggedInRoutes/AdminRoutes";
import styled from "styled-components";

interface INotification {
  Icon: SvgIconComponent;
  title: React.ReactChild | string;
  subtitle: React.ReactChild | string;
  to?: string;
  onClick?: () => void;
}

interface IProps {
  anchorEl: any;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  container: {
    width: "25vw",
    minWidth: 250,
  },
}));

const Emp = styled.span`
  color: cornflowerblue;
`;

function NotificationPopover(props: IProps) {
  const { anchorEl, open, onClose } = props;
  const classes = useStyles({});
  const dispatch = useDispatch();

  const counter = useSelector<RootState, ICounter>(
    (state) => state.statistic.counter
  );

  // fetch
  const fetch = React.useCallback(async () => {
    return true;
  }, [dispatch]);

  // do fetch every `refreshDelay` milliseconds
  const refreshDelay = 5000;
  const intervalRun = useIntervalRun(fetch, refreshDelay);
  const { setAlive: setFetchAlive } = intervalRun;

  // onModuleLoad
  const onModuleLoad = React.useCallback(() => {
    setFetchAlive(true);
  }, [setFetchAlive]);

  const notifications = React.useMemo(() => {
    const notifications: INotification[] = [];

    if (Boolean(counter)) {
      if (counter.identification >= 1)
        notifications.push({
          Icon: PeopleIcon,
          title: (
            <span>
              {counter.identification} <Emp>Identification</Emp> awaiting review
            </span>
          ),
          subtitle: (
            <span>
              Click to visit <Emp>Identification</Emp> page
            </span>
          ),
          to: adminRoutes.IDENTIFICATION.path,
        });

      if (counter.on_shipping_to_depatu >= 1)
        notifications.push({
          Icon: ReceiptIcon,
          title: (
            <span>
              {counter.on_shipping_to_depatu} <Emp>Transaction</Emp> awaiting
              arrival confirmation
            </span>
          ),
          subtitle: (
            <span>
              Click to visit <Emp>Transaction</Emp> page
            </span>
          ),
          to: adminRoutes.BNIB_TRANSACTION.path,
        });

      if (counter.withdraw_request >= 1)
        notifications.push({
          Icon: VerticalAlignTopIcon,
          title: (
            <span>
              {counter.withdraw_request} <Emp>Withdraw Request</Emp> awaiting
              approval
            </span>
          ),
          subtitle: (
            <span>
              Click to visit <Emp>Withdraw Request</Emp> page
            </span>
          ),
          to: adminRoutes.WITHDRAW_REQUEST.path,
        });
    }

    return notifications;
  }, [counter]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      onClose={onClose}
    >
      <div className={classes.container}>
        <Fetch<boolean>
          todo={fetch}
          onLoad={onModuleLoad}
          renderLoading={() => <CircleProgress />}
          renderError={(error, retry) => <CommonError retry={retry} />}
          renderSuccess={() => (
            <>
              {notifications.map((notification, index) => (
                <>
                  {Boolean(index) && <Divider />}
                  <NotificationPane
                    key={index}
                    Icon={notification.Icon}
                    title={notification.title}
                    subtitle={notification.subtitle}
                    to={notification.to}
                    onClick={notification.onClick}
                    onClose={onClose}
                  />
                </>
              ))}
            </>
          )}
        />
      </div>
    </Popover>
  );
}

export default NotificationPopover;
