import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import {
  EDepositFeeSortField,
  IDepositFee,
  PDepositFee,
  PDepositFeeFilter,
} from "../entities";
import IDepositFeeRepository from "../repository";

export default class DepositFeeRepository
  implements IDepositFeeRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private DEPOSIT_FEE_ROUTE = "/admin/deposit-fee";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  buildFilter = (filter: PDepositFeeFilter) => {
    return filter;
  };

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getDepositFee(
    pagination: IPagination,
    filter: PDepositFeeFilter,
    sorts: ISort<EDepositFeeSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IDepositFee[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      Boolean
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.DEPOSIT_FEE_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getDepositFeeById(id: number): Promise<IDepositFee> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.DEPOSIT_FEE_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async createDepositFee(depositFee: PDepositFee): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(this.DEPOSIT_FEE_ROUTE, depositFee)
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async updateDepositFee(id: number, depositFee: PDepositFee): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(
        `${this.DEPOSIT_FEE_ROUTE}/${id}`,
        depositFee
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async deleteDepositFee(id: number): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().delete(`${this.DEPOSIT_FEE_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
