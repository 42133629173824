import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { produce } from "immer";
import { Reducer } from "redux";
import {
  EConfigSortField,
  IConfig,
  PConfigFilter,
} from "src/core/config/entities";
import { ActionType, createAction, getType } from "typesafe-actions";

/*
 * state
 */
export interface IConfigState {
  configs: IConfig[];
  loading: boolean;
  error: any;
  meta: IMeta;
}

const initialState: IConfigState = {
  configs: [],
  loading: false,
  error: null,
  meta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
};

/*
 * action types
 */
const FETCH_REQUESTED = "config/FETCH_REQUESTED";
const FETCH_SUCCEEDED = "config/FETCH_SUCCEEDED";
const FETCH_FAILED = "config/FETCH_FAILED";
const POLLING_START_REQUESTED = "config/POLLING_START_REQUESTED";
const POLLING_STOP_REQUESTED = "config/POLLING_STOP_REQUESTED";

/*
 * action creators
 */
export const actions = {
  fetchRequested: createAction(FETCH_REQUESTED)(),

  fetchSucceeded: createAction(FETCH_SUCCEEDED)<{
    data: IConfig[];
    meta: IMeta;
  }>(),

  fetchFailed: createAction(FETCH_FAILED)<any>(),

  pollingStartRequested: createAction(POLLING_START_REQUESTED)<{
    filter: PConfigFilter;
    pagination: IPagination;
    sorts: ISort<EConfigSortField>[];
    refreshDelay: number;
  }>(),

  pollingStopRequested: createAction(POLLING_STOP_REQUESTED)(),
};
export type TActions = ActionType<typeof actions>;

const reducer: Reducer<IConfigState, TActions> = (
  state = initialState,
  action
) => {
  return produce<IConfigState>(state, (draft) => {
    switch (action.type) {
      case getType(actions.fetchRequested): {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case getType(actions.fetchSucceeded): {
        const {
          payload: { data, meta },
        } = action;
        draft.configs = data;
        draft.loading = false;
        draft.error = null;
        draft.meta = meta;
        break;
      }
      case getType(actions.fetchFailed): {
        const { payload } = action;
        draft.loading = false;
        draft.error = payload;
        break;
      }
    }
  });
};

export default reducer;
