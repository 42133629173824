import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EPromoCodeSortField,
  IPromoCode,
  PPromoCode,
  PPromoCodeFilter,
} from "../entities";
import IPromoCodeRepository from "../repository";
import IPromoCodeUsecase from "../usecase";

export default class PromoCodeUsecase
  implements IPromoCodeUsecase<CancelTokenSource> {
  private promoCodeRepo: IPromoCodeRepository<CancelTokenSource>;

  constructor(promoCodeRepo: IPromoCodeRepository<CancelTokenSource>) {
    this.promoCodeRepo = promoCodeRepo;
  }

  async getPromoCode(
    pagination: IPagination,
    filter: PPromoCodeFilter,
    sorts: ISort<EPromoCodeSortField>[],
    signal: CancelTokenSource
  ): Promise<[IPromoCode[], IMeta]> {
    return await this.promoCodeRepo.getPromoCode(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getPromoCodeById(id: number): Promise<IPromoCode> {
    return await this.promoCodeRepo.getPromoCodeById(id);
  }

  async createPromoCode(promoCode: PPromoCode, image: any): Promise<void> {
    const formData = new FormData();
    formData.append("active_status", String(promoCode.active_status));
    formData.append("code", promoCode.code);
    formData.append("expired_at", promoCode.expired_at);
    formData.append("percentage", String(promoCode.percentage));
    formData.append("product_type", String(promoCode.product_type));
    formData.append("limit", String(promoCode.limit));
    if (typeof promoCode.description === "string")
      formData.append("description", promoCode.description);
    formData.append("image", image);

    await this.promoCodeRepo.createPromoCode(formData);
  }

  async updatePromoCode(
    oldPromoCode: PPromoCode,
    newPromoCode: PPromoCode,
    image: any
  ): Promise<void> {
    const formData = new FormData();
    if (oldPromoCode.code !== newPromoCode.code)
      formData.append("code", newPromoCode.code);
    formData.append("active_status", String(newPromoCode.active_status));
    formData.append("expired_at", newPromoCode.expired_at);
    formData.append("percentage", String(newPromoCode.percentage));
    formData.append("product_type", String(newPromoCode.product_type));
    formData.append("limit", String(newPromoCode.limit));
    if (typeof newPromoCode.description === "string")
      formData.append("description", newPromoCode.description);
    if (image) formData.append("image", image);

    await this.promoCodeRepo.updatePromoCode(oldPromoCode.id, formData);
  }

  async deletePromoCode(id: number): Promise<void> {
    await this.promoCodeRepo.deletePromoCode(id);
  }
}
