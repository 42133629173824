import { IAuthUser } from "../entities";
import IAuthRepository from "../repository";
import IAuthUsecase from "../usecase";

export default class AuthUsecase implements IAuthUsecase {
  private authRepo: IAuthRepository;

  constructor(authRepo: IAuthRepository) {
    this.authRepo = authRepo;
  }

  async signIn(credentials: {
    username: string;
    password: string;
  }): Promise<{ token: string; user: IAuthUser }> {
    return await this.authRepo.signIn(credentials);
  }

  async getMe(): Promise<IAuthUser> {
    return await this.authRepo.getMe();
  }

  async refreshToken(): Promise<string> {
    return await this.authRepo.refreshToken();
  }
}
