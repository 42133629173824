import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import {
  EBnibProductSortField,
  IBnibProduct,
  PBnibProductFilter,
} from "../entities";
import IBnibProductRepository from "../repository";
import IBnibProductUsecase from "../usecase";

export default class BnibProductUsecase
  implements IBnibProductUsecase<CancelTokenSource> {
  private bnibProductRepo: IBnibProductRepository<CancelTokenSource>;

  constructor(bnibProductRepo: IBnibProductRepository<CancelTokenSource>) {
    this.bnibProductRepo = bnibProductRepo;
  }

  async getBnibProduct(
    userId: number,
    pagination: IPagination,
    filter: PBnibProductFilter,
    sorts: ISort<EBnibProductSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IBnibProduct[], IMeta]> {
    return await this.bnibProductRepo.getBnibProduct(
      userId,
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getBnibProductByCode(code: string): Promise<IBnibProduct> {
    return await this.bnibProductRepo.getBnibProductByCode(code);
  }
}
