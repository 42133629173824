import { goPromise } from "@delanobgt/admin-core/util/helpers";
import _ from "lodash";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";

import { IShippingAddress } from "../entities";
import IShippingAddressRepository from "../repository";

export default class ShippingAddressRepository
  implements IShippingAddressRepository {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private SHIPPING_ADDRESS_ROUTE = "/admin/shipping-address";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getShippingAddressById(id: number): Promise<IShippingAddress> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.SHIPPING_ADDRESS_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async getShippingAddressByUserId(
    user_id: number
  ): Promise<IShippingAddress[]> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(this.SHIPPING_ADDRESS_ROUTE, {
        params: {
          user_id,
        },
      })
    );
    if (err) {
      throw this.getError(err);
    }
    const { data } = response.data;
    return data;
  }
}
