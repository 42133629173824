import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import {
  ApiClientFactory,
  IMeta,
  IPagination,
  ISort,
} from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import { ERevenueSortField, IRevenue, PRevenueFilter } from "../entities";
import IRevenueRepository from "../repository";

export default class RevenueRepository
  implements IRevenueRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private REVENUE_ROUTE = "/admin/statistic/revenue";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private buildFilter(filter: PRevenueFilter) {
    const created_at = filter.created_at_enabled
      ? `${filter.created_at_start},${filter.created_at_end}`
      : undefined;

    const clonedFilter = { ...filter };
    delete clonedFilter.created_at_enabled;
    delete clonedFilter.created_at_start;
    delete clonedFilter.created_at_end;

    return _.pickBy(
      {
        ...clonedFilter,
        created_at,
      },
      Boolean
    );
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getRevenue(
    pagination: IPagination,
    filter: PRevenueFilter,
    sorts: ISort<ERevenueSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IRevenue[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      Boolean
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.REVENUE_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }
}
