import { goPromise } from "@delanobgt/admin-core/util/helpers";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";
import _ from "lodash";

import { ILegitCheck, ILegitCheckDetail, PLegitCheckDetail } from "../entities";
import ILegitCheckRepository from "../repository";

export default class LegitCheckRepository implements ILegitCheckRepository {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private LEGIT_CHECK_ROUTE = "/admin/legit-check";
  private LEGIT_CHECK_DETAIL_ROUTE = "/admin/legit-check-detail";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getLegitCheckByBnibTransactionId(id: number): Promise<ILegitCheck> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.LEGIT_CHECK_ROUTE}`, {
        params: { bnib_transaction_id: id },
      })
    );
    if (err) {
      throw this.getError(err);
    }

    const legitCheck: ILegitCheck =
      response.data.data && response.data.data.length
        ? response.data.data[0]
        : null;

    if (legitCheck)
      legitCheck.image_urls = legitCheck.image_url
        ? legitCheck.image_url.split(/,/)
        : [];

    return legitCheck;
  }

  async createLegitCheck(bnibTransactionId: number): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(`${this.LEGIT_CHECK_ROUTE}`, {
        bnib_transaction_id: bnibTransactionId,
      })
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async updateLegitCheck(
    legitCheckId: number,
    formData: FormData
  ): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(
        `${this.LEGIT_CHECK_ROUTE}/${legitCheckId}`,
        formData
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async publishFinalResult(id: number, final_result: string): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(`${this.LEGIT_CHECK_ROUTE}/${id}/result`, {
        final_result,
      })
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async getLegitCheckDetails(
    legit_check_id: number
  ): Promise<ILegitCheckDetail[]> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.LEGIT_CHECK_DETAIL_ROUTE}`, {
        params: {
          legit_check_id,
        },
      })
    );
    if (err) {
      throw this.getError(err);
    }

    const legitCheckDetails: ILegitCheckDetail[] = response.data.data;
    return legitCheckDetails;
  }

  async getLegitCheckDetailById(id: number): Promise<ILegitCheckDetail> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.LEGIT_CHECK_DETAIL_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }

    return response.data;
  }

  async createLegitCheckDetail(
    legitCheckDetail: PLegitCheckDetail
  ): Promise<void> {
    const [err, response] = await goPromise(
      this.apiClientFactory().post(
        `${this.LEGIT_CHECK_DETAIL_ROUTE}`,
        legitCheckDetail
      )
    );
    if (err) {
      throw this.getError(err);
    }

    return response.data;
  }

  async updateLegitCheckDetail(
    oldLegitCheckDetail: PLegitCheckDetail,
    newLegitCheckDetail: PLegitCheckDetail
  ): Promise<void> {
    const [err, response] = await goPromise(
      this.apiClientFactory().patch(
        `${this.LEGIT_CHECK_DETAIL_ROUTE}/${oldLegitCheckDetail.id}`,
        newLegitCheckDetail
      )
    );
    if (err) {
      throw this.getError(err);
    }

    return response.data;
  }
}
