import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { produce } from "immer";
import { Reducer } from "redux";
import {
  EBannerSortField,
  IBanner,
  PBannerFilter,
} from "src/core/banner/entities";
import { ActionType, createAction, getType } from "typesafe-actions";

/*
 * state
 */
export interface IBannerState {
  banners: IBanner[];
  loading: boolean;
  error: any;
  meta: IMeta;
}

const initialState: IBannerState = {
  banners: [],
  loading: false,
  error: null,
  meta: {
    limit: 10,
    offset: 0,
    total: 0,
  },
};

/*
 * action types
 */
const FETCH_REQUESTED = "banner/FETCH_REQUESTED";
const FETCH_SUCCEEDED = "banner/FETCH_SUCCEEDED";
const FETCH_FAILED = "banner/FETCH_FAILED";
const POLLING_START_REQUESTED = "banner/POLLING_START_REQUESTED";
const POLLING_STOP_REQUESTED = "banner/POLLING_STOP_REQUESTED";

/*
 * action creators
 */
export const actions = {
  fetchRequested: createAction(FETCH_REQUESTED)(),

  fetchSucceeded: createAction(FETCH_SUCCEEDED)<{
    data: IBanner[];
    meta: IMeta;
  }>(),

  fetchFailed: createAction(FETCH_FAILED)<any>(),

  pollingStartRequested: createAction(POLLING_START_REQUESTED)<{
    filter: PBannerFilter;
    pagination: IPagination;
    sorts: ISort<EBannerSortField>[];
    refreshDelay: number;
  }>(),

  pollingStopRequested: createAction(POLLING_STOP_REQUESTED)(),
};
export type TActions = ActionType<typeof actions>;

const reducer: Reducer<IBannerState, TActions> = (
  state = initialState,
  action
) => {
  return produce<IBannerState>(state, (draft) => {
    switch (action.type) {
      case getType(actions.fetchRequested): {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case getType(actions.fetchSucceeded): {
        const {
          payload: { data, meta },
        } = action;
        draft.banners = data;
        draft.loading = false;
        draft.error = null;
        draft.meta = meta;
        break;
      }
      case getType(actions.fetchFailed): {
        const { payload } = action;
        draft.loading = false;
        draft.error = payload;
        break;
      }
    }
  });
};

export default reducer;
