import {
  Money as MoneyIcon,
  People as PeopleIcon,
  Receipt as ReceiptIcon,
  Style as StyleIcon,
  TrendingUp as TrendingUpIcon,
} from "@material-ui/icons";
import { adminRoutes } from "src/Router/router/routes/loggedInRoutes/AdminRoutes";

import { IMenu } from "./types";

export const menuList: IMenu[] = [
  {
    title: "Dashboard",
    Icon: adminRoutes.DASHBOARD.Icon,
    link: adminRoutes.DASHBOARD.path,
  },
  {
    title: "Banner",
    Icon: adminRoutes.BANNER.Icon,
    link: process.env.REACT_APP_CELESTIAL_BANNER_URL,
    target: "_blank",
  },
  {
    title: "Custom Tag",
    Icon: adminRoutes.BANNER.Icon,
    link: process.env.REACT_APP_CELESTIAL_TAG_URL,
    target: "_blank",
  },
  {
    title: "Custom Tracking Template",
    Icon: adminRoutes.BANNER.Icon,
    link: process.env.REACT_APP_CELESTIAL_CUSTOM_TRACKING_URL,
    target: "_blank",
  },
  {
    title: "BNIB",
    Icon: ReceiptIcon,
    subMenus: [
      {
        title: "Buy Orders",
        Icon: adminRoutes.BNIB_BUY_ORDER.Icon,
        link: process.env.REACT_APP_CELESTIAL_BNIB_BUY_ORDER_URL,
        target: "_blank",
      },
      {
        title: "On-Selling Products",
        Icon: adminRoutes.BNIB_PRODUCT.Icon,
        link: process.env.REACT_APP_CELESTIAL_BNIB_PRODUCT_URL,
        target: "_blank",
      },
      {
        title: "Transaction",
        Icon: adminRoutes.BNIB_TRANSACTION.Icon,
        link: adminRoutes.BNIB_TRANSACTION.path,
      },
    ],
  },
  {
    title: "Config",
    Icon: adminRoutes.CONFIG.Icon,
    link: adminRoutes.CONFIG.path,
  },
  {
    title: "Protection Fee",
    Icon: adminRoutes.DEPOSIT_FEE.Icon,
    link: adminRoutes.DEPOSIT_FEE.path,
  },
  {
    title: "Product",
    Icon: StyleIcon,
    subMenus: [
      {
        title: "Product",
        Icon: adminRoutes.PRODUCT.Icon,
        link: process.env.REACT_APP_CELESTIAL_PRODUCT_URL,
        target: "_blank",
      },
      {
        title: "Review Product",
        Icon: adminRoutes.PRODUCT.Icon,
        link: process.env.REACT_APP_CELESTIAL_REVIEW_PRODUCT_URL,
        target: "_blank",
      },
      {
        title: "Sizing",
        Icon: adminRoutes.PRODUCT.Icon,
        link: process.env.REACT_APP_CELESTIAL_SIZING_URL,
        target: "_blank",
      },
      {
        title: "Product Brand",
        Icon: adminRoutes.PRODUCT_BRAND.Icon,
        link: adminRoutes.PRODUCT_BRAND.path,
      },
      {
        title: "Product Category Group",
        Icon: adminRoutes.PRODUCT_CATEGORY_GROUP.Icon,
        link: adminRoutes.PRODUCT_CATEGORY_GROUP.path,
      },
      {
        title: "Product Category",
        Icon: adminRoutes.PRODUCT_CATEGORY.Icon,
        link: adminRoutes.PRODUCT_CATEGORY.path,
      },
      {
        title: "Featured Product",
        Icon: adminRoutes.FEATURED_PRODUCT.Icon,
        link: process.env.REACT_APP_CELESTIAL_FEATURED_PRODUCT_URL,
        target: "_blank",
      },
      {
        title: "Product Request",
        Icon: adminRoutes.PRODUCT_REQUEST.Icon,
        link: adminRoutes.PRODUCT_REQUEST.path,
      },
    ],
  },
  {
    title: "Promo Code",
    Icon: adminRoutes.PROMO_CODE.Icon,
    link: adminRoutes.PROMO_CODE.path,
  },
  {
    title: "Special Category",
    Icon: adminRoutes.SPECIAL_CATEGORY.Icon,
    link: process.env.REACT_APP_CELESTIAL_SPECIAL_CATEGORY_URL,
    target: "_blank",
  },
  {
    title: "User",
    Icon: PeopleIcon,
    subMenus: [
      {
        title: "User",
        Icon: adminRoutes.USER.Icon,
        link: adminRoutes.USER.path,
      },
      {
        title: "Identification",
        Icon: adminRoutes.IDENTIFICATION.Icon,
        link: adminRoutes.IDENTIFICATION.path,
      },
    ],
  },
  {
    title: "Statistics",
    Icon: TrendingUpIcon,
    subMenus: [
      {
        title: "Revenue",
        Icon: adminRoutes.REVENUE.Icon,
        link: adminRoutes.REVENUE.path,
      },
      {
        title: "Floating Fund",
        Icon: adminRoutes.FLOATING_FUND.Icon,
        link: adminRoutes.FLOATING_FUND.path,
      },
      {
        title: "Newsletter",
        Icon: adminRoutes.NEWSLETTER.Icon,
        link: adminRoutes.NEWSLETTER.path,
      },
    ],
  },
  {
    title: "Wallet",
    Icon: MoneyIcon,
    subMenus: [
      {
        title: "Withdraw Request",
        Icon: adminRoutes.WITHDRAW_REQUEST.Icon,
        link: adminRoutes.WITHDRAW_REQUEST.path,
      },
      {
        title: "Top Up",
        Icon: adminRoutes.TOP_UP.Icon,
        link: adminRoutes.TOP_UP.path,
      },
    ],
  },
  {
    title: "Admin User",
    Icon: adminRoutes.ADMIN_USER.Icon,
    link: adminRoutes.ADMIN_USER.path,
  },
  {
    title: "Logout",
    Icon: adminRoutes.LOGOUT.Icon,
    link: adminRoutes.LOGOUT.path,
  },
];
