import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";

import { EConfigSortField, IConfig, PConfigFilter, PConfig } from "../entities";
import IConfigRepository from "../repository";
import IConfigUsecase from "../usecase";

export default class ConfigUsecase
  implements IConfigUsecase<CancelTokenSource> {
  private configRepo: IConfigRepository<CancelTokenSource>;

  constructor(configRepo: IConfigRepository<CancelTokenSource>) {
    this.configRepo = configRepo;
  }

  async getConfig(
    pagination: IPagination,
    filter: PConfigFilter,
    sorts: ISort<EConfigSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IConfig[], IMeta]> {
    return await this.configRepo.getConfig(pagination, filter, sorts, signal);
  }

  async getConfigByName(name: string): Promise<IConfig> {
    return await this.configRepo.getConfigByName(name);
  }

  async updateConfig(config: PConfig): Promise<void> {
    await this.configRepo.updateConfig(config.id, config);
  }
}
