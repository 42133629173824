import { ILegitCheck, ILegitCheckDetail, PLegitCheckDetail } from "../entities";
import ILegitCheckRepository from "../repository";
import ILegitCheckUsecase from "../usecase";

export default class LegitCheckUsecase implements ILegitCheckUsecase {
  private legitCheckRepo: ILegitCheckRepository;

  constructor(legitCheckRepo: ILegitCheckRepository) {
    this.legitCheckRepo = legitCheckRepo;
  }

  async getLegitCheckDetails(
    legit_check_id: number
  ): Promise<ILegitCheckDetail[]> {
    return await this.legitCheckRepo.getLegitCheckDetails(legit_check_id);
  }

  async getLegitCheckDetailById(id: number): Promise<ILegitCheckDetail> {
    return await this.legitCheckRepo.getLegitCheckDetailById(id);
  }

  async getLegitCheckByBnibTransactionId(id: number): Promise<ILegitCheck> {
    return await this.legitCheckRepo.getLegitCheckByBnibTransactionId(id);
  }

  async createLegitCheck(legitCheckId: number): Promise<void> {
    await this.legitCheckRepo.createLegitCheck(legitCheckId);
  }

  async updateLegitCheck(
    legitCheckId: number,
    detail_images: any[],
    initial_images: { image_path: string; deleted: boolean }[]
  ): Promise<void> {
    const formData = new FormData();
    if (detail_images) {
      for (let image of detail_images) formData.append("new_images[]", image);
    }
    const deleted_detail_images = initial_images
      .filter((img) => img.deleted)
      .map((img) => img.image_path)
      .join(",");
    if (Boolean(deleted_detail_images)) {
      formData.append("deleted_images", deleted_detail_images);
    }

    await this.legitCheckRepo.updateLegitCheck(legitCheckId, formData);
  }

  async publishFinalResult(id: number, final_result: string): Promise<void> {
    await this.legitCheckRepo.publishFinalResult(id, final_result);
  }

  async createLegitCheckDetail(
    legitCheckDetail: PLegitCheckDetail
  ): Promise<void> {
    legitCheckDetail.price = Number(legitCheckDetail.price);
    return await this.legitCheckRepo.createLegitCheckDetail(legitCheckDetail);
  }

  async updateLegitCheckDetail(
    oldLegitCheckDetail: PLegitCheckDetail,
    newLegitCheckDetail: PLegitCheckDetail
  ): Promise<void> {
    newLegitCheckDetail.price = Number(newLegitCheckDetail.price);
    return await this.legitCheckRepo.updateLegitCheckDetail(
      oldLegitCheckDetail,
      newLegitCheckDetail
    );
  }
}
