import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";
import shallowDiff from "shallow-diff";

import {
  ESpecialCategorySortField,
  ISpecialCategory,
  PSpecialCategory,
  PSpecialCategoryFilter,
} from "../entities";
import ISpecialCategoryRepository from "../repository";
import ISpecialCategoryUsecase from "../usecase";

export default class SpecialCategoryUsecase
  implements ISpecialCategoryUsecase<CancelTokenSource> {
  private specialCategoryRepo: ISpecialCategoryRepository<CancelTokenSource>;

  constructor(
    specialCategoryRepo: ISpecialCategoryRepository<CancelTokenSource>
  ) {
    this.specialCategoryRepo = specialCategoryRepo;
  }

  async getSpecialCategory(
    pagination: IPagination,
    filter: PSpecialCategoryFilter,
    sorts: ISort<ESpecialCategorySortField>[],
    signal: CancelTokenSource
  ): Promise<[ISpecialCategory[], IMeta]> {
    return await this.specialCategoryRepo.getSpecialCategory(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getSpecialCategoryById(id: number): Promise<ISpecialCategory> {
    return await this.specialCategoryRepo.getSpecialCategoryById(id);
  }

  async createSpecialCategory(
    specialCategory: PSpecialCategory
  ): Promise<void> {
    await this.specialCategoryRepo.createSpecialCategory(specialCategory);
  }

  async updateSpecialCategory(
    oldSpecialCategory: PSpecialCategory,
    newSpecialCategory: PSpecialCategory
  ): Promise<void> {
    const specialCategory = _.pick(
      newSpecialCategory,
      shallowDiff(oldSpecialCategory, newSpecialCategory).updated
    ) as PSpecialCategory;

    await this.specialCategoryRepo.updateSpecialCategory(
      oldSpecialCategory.id,
      specialCategory
    );
  }

  async deleteSpecialCategory(id: number): Promise<void> {
    await this.specialCategoryRepo.deleteSpecialCategory(id);
  }
}
