import { goPromise } from "@delanobgt/admin-core/util/helpers";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";
import _ from "lodash";

import { IProductSize } from "../entities";
import IProductSizeRepository from "../repository";

export default class ProductSizeRepository implements IProductSizeRepository {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private PRODUCT_SIZE_ROUTE = "/admin/product-size";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getProductSizeByProductCategoryId(
    productCategoryId: number
  ): Promise<IProductSize[]> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(
        `${this.PRODUCT_SIZE_ROUTE}?product_category_id=${productCategoryId}`,
        {
          params: { limit: 100 },
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data.data;
  }

  async createProductSize(productSize: IProductSize): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(`${this.PRODUCT_SIZE_ROUTE}`, productSize)
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async updateProductSize(productSize: IProductSize): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(
        `${this.PRODUCT_SIZE_ROUTE}/${productSize.id}`,
        productSize
      )
    );
    if (err) {
      throw this.getError(err);
    }
  }
}
