import { goPromise } from "@delanobgt/admin-core/util/helpers";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";

import { IAdminUser, INewAdminUser, PNewAdminUser, Role } from "../entities";
import IAdminUserRepository from "../repository";

export default class AdminUserRepository
  implements IAdminUserRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private ADMIN_USER_ROUTE = "/admin/admin-user";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getAdminUser(signal: CancelTokenSource): Promise<IAdminUser[]> {
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        this.ADMIN_USER_ROUTE,
        {
          params: {
            limit: 100,
          },
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data } = response.data;
    return data;
  }

  async getAdminUserById(id: number): Promise<IAdminUser> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.ADMIN_USER_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }

  async createAdminUser(adminUser: INewAdminUser): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().post(this.ADMIN_USER_ROUTE, adminUser)
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async updateAdminUser(id: number, adminUser: PNewAdminUser): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().patch(`${this.ADMIN_USER_ROUTE}/${id}`, adminUser)
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async deleteAdminUser(id: number): Promise<void> {
    const [err] = await goPromise(
      this.apiClientFactory().delete(`${this.ADMIN_USER_ROUTE}/${id}`)
    );
    if (err) {
      throw this.getError(err);
    }
  }

  async getAdminUserRole(): Promise<Role[]> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.ADMIN_USER_ROUTE}/role`, {
        params: {
          limit: 100,
        },
      })
    );
    if (err) {
      throw this.getError(err);
    }
    const { data } = response;
    return data;
  }
}
