import { CancelTokenSource } from "axios";

import { ICounter, IFund, IGeneralStatistic } from "../entities";
import IStatisticRepository from "../repository";
import IStatisticUsecase from "../usecase";

export default class StatisticUsecase
  implements IStatisticUsecase<CancelTokenSource> {
  private statisticRepo: IStatisticRepository<CancelTokenSource>;

  constructor(statisticRepo: IStatisticRepository<CancelTokenSource>) {
    this.statisticRepo = statisticRepo;
  }

  async getFund(signal: CancelTokenSource): Promise<IFund> {
    return await this.statisticRepo.getFund(signal);
  }

  async getGeneralStatistic(
    signal: CancelTokenSource
  ): Promise<IGeneralStatistic> {
    return await this.statisticRepo.getGeneralStatistic(signal);
  }

  async getCounter(signal: CancelTokenSource): Promise<ICounter> {
    return await this.statisticRepo.getCounter(signal);
  }

  async getNewsletterExcelData(month: string, year: string): Promise<any> {
    return await this.statisticRepo.getNewsletterExcelData(month, year);
  }
}
