import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";
import shallowDiff from "shallow-diff";

import {
  EProductCategorySortField,
  IProductCategory,
  PProductCategoryFilter,
} from "../entities";
import IProductCategoryRepository from "../repository";
import IProductCategoryUsecase from "../usecase";

export default class ProductCategoryUsecase
  implements IProductCategoryUsecase<CancelTokenSource> {
  private productCategoryRepo: IProductCategoryRepository<CancelTokenSource>;

  constructor(
    productCategoryRepo: IProductCategoryRepository<CancelTokenSource>
  ) {
    this.productCategoryRepo = productCategoryRepo;
  }

  async getProductCategory(
    pagination: IPagination,
    filter: PProductCategoryFilter,
    sorts: ISort<EProductCategorySortField>[],
    signal?: CancelTokenSource
  ): Promise<[IProductCategory[], IMeta]> {
    return await this.productCategoryRepo.getProductCategory(
      pagination,
      filter,
      sorts,
      signal
    );
  }

  async getProductCategoryById(id: number): Promise<IProductCategory> {
    return await this.productCategoryRepo.getProductCategoryById(id);
  }

  async createProductCategory(
    productCategory: IProductCategory
  ): Promise<void> {
    await this.productCategoryRepo.createProductCategory(productCategory);
  }

  async updateProductCategory(
    oldProductCategory: IProductCategory,
    newProductCategory: IProductCategory
  ): Promise<void> {
    const diffProductCategory = _.pick(
      newProductCategory,
      shallowDiff(oldProductCategory, newProductCategory).updated
    ) as IProductCategory;

    await this.productCategoryRepo.updateProductCategory(
      oldProductCategory.id,
      diffProductCategory
    );
  }
}
