import { buildSort, goPromise } from "@delanobgt/admin-core/util/helpers";
import { IMeta, IPagination, ISort } from "@delanobgt/admin-core/util/types";
import { CancelTokenSource } from "axios";
import _ from "lodash";
import { ApiClientFactory } from "@delanobgt/admin-core/util/types";

import {
  EBnibBuyOrderSortField,
  IBnibBuyOrder,
  PBnibBuyOrderFilter,
} from "../entities";
import IBnibBuyOrderRepository from "../repository";

export default class BnibBuyOrderRepository
  implements IBnibBuyOrderRepository<CancelTokenSource> {
  private apiClientFactory: ApiClientFactory;

  // api routes
  private BNIB_BUY_ORDER_ROUTE = "/admin/bnib-buy-order";

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClientFactory = apiClientFactory;
  }

  private buildFilter(filter: PBnibBuyOrderFilter) {
    return filter;
  }

  private getSignalToken(signal: CancelTokenSource) {
    return _.get(signal, "token", null);
  }

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getBnibBuyOrder(
    pagination: IPagination,
    filter: PBnibBuyOrderFilter,
    sorts: ISort<EBnibBuyOrderSortField>[],
    signal?: CancelTokenSource
  ): Promise<[IBnibBuyOrder[], IMeta]> {
    const sort = buildSort(sorts);
    const params = _.pickBy(
      {
        ...pagination,
        ...this.buildFilter(filter),
      },
      Boolean
    );
    const [err, response] = await goPromise(
      this.apiClientFactory({ cancelToken: this.getSignalToken(signal) }).get(
        `${this.BNIB_BUY_ORDER_ROUTE}?sort=${sort}`,
        {
          params,
        }
      )
    );
    if (err) {
      throw this.getError(err);
    }
    const { data, meta } = response.data;
    return [data, meta];
  }

  async getBnibBuyOrderByCode(code: string): Promise<IBnibBuyOrder> {
    const [err, response] = await goPromise(
      this.apiClientFactory().get(`${this.BNIB_BUY_ORDER_ROUTE}/${code}`)
    );
    if (err) {
      throw this.getError(err);
    }
    return response.data;
  }
}
