import { produce } from "immer";
import { Reducer } from "redux";
import { IAdminUser } from "src/core/adminUser/entities";
import { IAuthUser } from "src/core/auth/entities";
import { ActionType, createAction, getType } from "typesafe-actions";

/*
 * state
 */
export interface IAuthState {
  token: string;
  user?: IAuthUser;
  defectBirth: boolean;
}

const initialState: IAuthState = {
  token: "",
  user: null,
  defectBirth: false,
};

/*
 * action types
 */
const SIGN_IN = "auth/SIGN_IN";
const SIGN_OUT = "auth/SIGN_OUT";
const GET_ME = "auth/GET_ME";
const SET_TOKEN = "auth/SET_TOKEN";
const DEFECT_BIRTH = "auth/DEFECT_BIRTH";

/*
 * action creators
 */
export const actions = {
  signIn: createAction(SIGN_IN)<{ token: string; user: IAuthUser }>(),

  signOut: createAction(SIGN_OUT)(),

  getMe: createAction(GET_ME)<IAdminUser>(),

  setToken: createAction(SET_TOKEN)<string>(),

  defectBirth: createAction(DEFECT_BIRTH)(),
};
export type TActions = ActionType<typeof actions>;

const reducer: Reducer<IAuthState, TActions> = (
  state = initialState,
  action
) => {
  return produce<IAuthState>(state, (draft) => {
    switch (action.type) {
      case getType(actions.signIn): {
        draft.token = action.payload.token;
        draft.user = action.payload.user;
        break;
      }
      case getType(actions.signOut): {
        draft.token = initialState.token;
        draft.user = initialState.user;
        break;
      }
      case getType(actions.getMe): {
        draft.user = action.payload;
        break;
      }
      case getType(actions.setToken): {
        draft.token = action.payload;
        break;
      }
      case getType(actions.defectBirth): {
        draft.defectBirth = true;
        break;
      }
    }
  });
};

export default reducer;
